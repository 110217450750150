import {
    generateLineIndexSequence,
    generateVerticesFromPointIds,
    generateSurfaceWithDefectedTris,
    generateBoundaryVertices,
    generateEdgeVertices,
} from './geometry';
import { getDefectedSurfaceSnapshot, getLinesSnapshot, getPointsSnapshot } from './snapshots';
import { DefectsSnapshot, DefectsState } from './types';
import { toDefectViewId, toElementViewId } from './uid';

export function generateTriangleDefects(
    defectName: string,
    { defects, surface, objectId }: DefectsState,
) {
    const triangleDefectsSnapshot = defects.reduce((acc: DefectsSnapshot, degen, index) => {
        const { vertices, triangles } = generateSurfaceWithDefectedTris(surface, degen.triangles);
        const triangleDefectsViewId = toDefectViewId(objectId, defectName, index);
        const surfaceSnapshot = getDefectedSurfaceSnapshot(
            toElementViewId(objectId, defectName, index),
            triangleDefectsViewId,
            vertices,
            triangles,
        );
        return {
            ...acc,
            [triangleDefectsViewId]: surfaceSnapshot,
        };
    }, {});
    return triangleDefectsSnapshot;
}

// TODO: Generalize this function to create snapshots for any type of defect that is represented by
// a collection of edges, e.g. fin boundaries, naked boundaries, etc.
export function generateHoleDefects(
    defectName: string,
    { defects, surface, objectId }: DefectsState,
) {
    const holeSnapshot = defects.reduce((acc: DefectsSnapshot, hole, index) => {
        const holeVertices = generateBoundaryVertices(hole.edges, surface);
        const holeLineSegmentViewId = toDefectViewId(objectId, defectName, index);
        const indices = generateLineIndexSequence(hole.edges.length + 1);
        const linesSnapshot = getLinesSnapshot(
            toElementViewId(objectId, defectName, index),
            holeLineSegmentViewId,
            holeVertices,
            indices,
        );
        return {
            ...acc,
            [holeLineSegmentViewId]: linesSnapshot,
        };
    }, {});
    return holeSnapshot;
}

export function generatePointDefects(
    defectName: string,
    { objectId, defects, surface }: DefectsState,
) {
    const pointDefectsSnapshot = defects.reduce((acc: DefectsSnapshot, point, index) => {
        const vertices = generateVerticesFromPointIds(point.points, surface);
        const pointDefectsViewId = toDefectViewId(objectId, defectName, index);
        const pointsSnapshot = getPointsSnapshot(
            toElementViewId(objectId, defectName, index),
            pointDefectsViewId,
            vertices,
        );
        return {
            ...acc,
            [pointDefectsViewId]: pointsSnapshot,
        };
    }, {});
    return pointDefectsSnapshot;
}

/**
 * Generate snapshots for edge defects where each edge represents an individual defect, e.g. for
 * non-manifold edges.
 * This function should not be used for defects that are represented by a collection of edges, e.g.
 * holes.
 */
export function generateEdgeDefects(
    defectName: string,
    { defects, surface, objectId }: DefectsState,
) {
    if (defects.length !== 1) throw Error('Bad edge defects data format');

    const edgeDefectsSnapshot = defects[0].edges.reduce((acc: DefectsSnapshot, edge, index) => {
        const edgeVertices = generateEdgeVertices(edge, surface);
        const edgeDefectsViewId = toDefectViewId(objectId, defectName, index);
        const indices = new Int32Array([0, 1]);
        const linesSnapshot = getLinesSnapshot(
            toElementViewId(objectId, defectName, index),
            edgeDefectsViewId,
            edgeVertices,
            indices,
        );
        return {
            ...acc,
            [edgeDefectsViewId]: linesSnapshot,
        };
    }, {});

    return edgeDefectsSnapshot;
}
