export const X_LABEL = 'X';
export const Y_LABEL = 'Y';
export const Z_LABEL = 'Z';
export const BOUNDING_BOX_TITLE = 'New Analytical Model';
export const NAME_TITLE = 'Model Name';
export const BOUNDING_BOX_DEFAULT_NAME = BOUNDING_BOX_TITLE;
export const MIN_TITLE = 'Minimum';
export const MAX_TITLE = 'Maximum';
export const NO_SELECTION_TEXT = 'Select a point on the model to create a bounding box.';
export const ACCEPT = 'Create';
export const CANCEL = 'Cancel';
export const BOX_VALUE_PRECISION = 10;
export const ENCLOSE_ENTIRE_MODEL = 'Enclose Entire Model';

export const ERROR_MAX_MUST_GREATER_THAN_MIN = 'Maximum must be greater than minimum.';

export const START_ANALYTICAL_BOUNDARY_CREATION_MESSAGE = `Starting to create the analytical boundary`;
export const ANALYTICAL_BOUNDARY_CREATION_SUCCESS_MESSAGE =
    'Analytical boundary creation successful.';
export const INITIALIZE_AGGREGATE_GEOM_MESSAGE = `Initializing aggregate geometry...`;
export const AGGREGATE_GEOM_INITIALIZE_SUCCESS_MESSAGE =
    'Aggregate geometry initialization successful.';
