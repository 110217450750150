import { AntSwitch } from '@local/web-design-system/dist/components/AntSwitch';
import { Dialog } from '@local/web-design-system/dist/components/Dialog';
import { NumericInput } from '@local/web-design-system/dist/components/NumericInput';
import { LinearProgressFaked } from '@local/web-design-system/dist/components/Progress/LinearProgressFaked';
import { PropertiesSelectMenu } from '@local/web-design-system/dist/components/Properties/PropertiesSelectMenu';
import Typography from '@mui/material/Typography';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    formGtmMeshTransformationBody,
    useLazyGtmMeshTransformationQuery,
} from 'src/apiClients/gtmCompute/gtmComputeApi';
import {
    GtmMeshTransformationAction,
    GtmMeshFillMode,
    GtmMeshFillHoleParams,
    ObjectIdWithVersion,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { GtmEvoOutputObject, GtmProject } from 'src/gtmProject/Project.types';
import { useDefectsLoadingManager } from 'src/hooks/defects/useDefectsLoadingManager';
import { useProjectManager } from 'src/hooks/project/useProjectManager';
import { useGooseContext } from 'src/hooks/useGooseContext';
import { useSceneObjectDataManager } from 'src/hooks/useSceneObjectDataManager';
import {
    selectCurrentProjectData,
    selectCurrentAnalyticalModelIndex,
    selectCurrentProjectVersionId,
    selectDefectsById,
} from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { selectedSceneObjects } from 'src/store/visualization/selectors';
import { makeHistoryEntry } from 'src/utils/history/history';
import { summarizeTransformationActionHistoryOperation } from 'src/utils/history/historySummary';

import {
    APPROXIMATE_FILL_HOLE_TIME,
    FILL_HOLES,
    FILL_MODE,
    FILL_MODE_INFO,
    SPECIFY_HOLE,
    SPECIFY_HOLE_INFO,
    FILL_REFINE_AND_FAIR,
    FILL_AND_REFINE,
    FILL,
    CANCEL,
} from './FillHoles.constants';
import { useStyles } from './FillHoles.styles';
import { ParameterRow } from './ParameterRow/ParameterRow';

export const FillHolesDialog = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
    const { classes } = useStyles();

    const gooseContext = useGooseContext();

    const selectedObject = Object.values(useAppSelector(selectedSceneObjects))[0];
    const selectedObjectHoles = useAppSelector(selectDefectsById(selectedObject.objectId)).defects
        .DetectHoles?.defects;

    const projectJson = useAppSelector(selectCurrentProjectData);
    const currentAnalyticalModelIndex = useAppSelector(selectCurrentAnalyticalModelIndex);
    const currentProjectVersionId = useAppSelector(selectCurrentProjectVersionId);
    const { uploadProject } = useProjectManager();
    const { runAllDetectors } = useDefectsLoadingManager();

    const { loadGtmObject } = useSceneObjectDataManager();
    const [GtmMeshTransformationTrigger] = useLazyGtmMeshTransformationQuery();

    const { projectName } = useParams();

    const [fillMode, setFillMode] = useState<GtmMeshFillMode>(GtmMeshFillMode.Fill);
    const [specifiedHoleIndex, setSpecifiedHoleIndex] = useState<number>(0);
    const [isHoleSelectionDisabled, setIsHoleSelectionDisabled] = useState<boolean>(true);
    const [isTransformationRunning, setIsTransformationRunning] = useState<boolean>(false);

    function anEdgeForEachHole() {
        return selectedObjectHoles?.map(({ edges }) => edges[0]) ?? [];
    }

    function fillHoleParams(): GtmMeshFillHoleParams {
        if (isHoleSelectionDisabled) {
            return { fillMode, edges: anEdgeForEachHole() };
        }
        return {
            fillMode,
            edges: selectedObjectHoles![specifiedHoleIndex].edges[0],
        };
    }

    const fillHoles = useCallback(
        async (object: ObjectIdWithVersion, params: GtmMeshFillHoleParams) => {
            const { data: result } = await GtmMeshTransformationTrigger(
                formGtmMeshTransformationBody(
                    gooseContext!,
                    GtmMeshTransformationAction.FillHoles,
                    [object],
                    params,
                ),
            );
            return result;
        },
        [gooseContext, GtmMeshTransformationTrigger],
    );

    const fillHolesForFirstSelectedObject = useCallback(
        async (params: GtmMeshFillHoleParams) => {
            const result = await fillHoles(
                { id: selectedObject.objectId, version: selectedObject.versionId },
                params,
            );

            if (!result || result.modified.length === 0) return undefined;

            const filledObject = result!.modified[0];

            await loadGtmObject(filledObject.id, filledObject.version);

            const gtmOutput = {
                id: filledObject.id,
                version_id: filledObject.version,
                name: selectedObject.name,
            } as GtmEvoOutputObject;
            await runAllDetectors(gtmOutput);

            return filledObject;
        },
        [selectedObject, fillHoles],
    );

    const action = useCallback(
        async (accept: boolean | null) => {
            if (accept && projectName) {
                setIsTransformationRunning(true);

                const transformationParams = fillHoleParams();

                const transformedObject =
                    await fillHolesForFirstSelectedObject(transformationParams);

                if (transformedObject) {
                    const updatedProjectJson: GtmProject = JSON.parse(JSON.stringify(projectJson));

                    const index = updatedProjectJson.analytical_models[
                        currentAnalyticalModelIndex
                    ].objects.findIndex((input) => input.id === transformedObject.id);

                    if (index !== -1) {
                        updatedProjectJson.analytical_models[currentAnalyticalModelIndex].objects[
                            index
                        ].version_id = transformedObject.version;
                    }

                    const newEntry = makeHistoryEntry(
                        summarizeTransformationActionHistoryOperation(
                            GtmMeshTransformationAction.FillHoles,
                            transformationParams,
                        ),
                        currentProjectVersionId,
                    );
                    updatedProjectJson.history = [newEntry, ...(updatedProjectJson.history || [])];

                    uploadProject(updatedProjectJson, projectName);
                }
                setIsTransformationRunning(false);
            }
            onClose();
        },
        [
            fillHolesForFirstSelectedObject,
            fillMode,
            specifiedHoleIndex,
            isHoleSelectionDisabled,
            currentProjectVersionId,
        ],
    );

    /* The behavior of this dialog is kind of finnicky. Enabling/disabling the edge selection inputs is weird, and the dropdown menu doesn't always select on first try. EH will fix in a follow up PR while working on GEOM-132. */
    return (
        <Dialog
            action={(response) => {
                action(response);
            }}
            dialogTitle={FILL_HOLES}
            confirmText={FILL}
            cancelText={CANCEL}
            open={open}
        >
            <ParameterRow
                ParameterElements={() => (
                    <>
                        <Typography className={classes.label}>{FILL_MODE}</Typography>
                        <PropertiesSelectMenu
                            title=""
                            options={[
                                { key: GtmMeshFillMode.Fill, label: FILL },
                                { key: GtmMeshFillMode.FillAndRefine, label: FILL_AND_REFINE },
                                {
                                    key: GtmMeshFillMode.FillRefineAndFair,
                                    label: FILL_REFINE_AND_FAIR,
                                },
                            ]}
                            onSelect={(newFillMode: string | number) =>
                                setFillMode(newFillMode as GtmMeshFillMode)
                            }
                            fullWidth
                            annotationsClassName={classes.control}
                            disableTitle
                        />
                    </>
                )}
                tooltipText={FILL_MODE_INFO}
            />
            <ParameterRow
                ParameterElements={() => (
                    <>
                        <Typography className={classes.label}>{SPECIFY_HOLE}</Typography>
                        <NumericInput
                            value={specifiedHoleIndex || 0}
                            onChange={(newValue) => setSpecifiedHoleIndex(newValue)}
                            min={0}
                            max={selectedObjectHoles!.length - 1}
                            disabled={isHoleSelectionDisabled}
                            className={classes.control}
                        />
                        <AntSwitch
                            onChange={(_, enabled: boolean) => setIsHoleSelectionDisabled(enabled)}
                            checked={isHoleSelectionDisabled}
                        />
                    </>
                )}
                tooltipText={SPECIFY_HOLE_INFO}
            />
            {isTransformationRunning && (
                <LinearProgressFaked
                    isLoading={isTransformationRunning}
                    milliseconds={APPROXIMATE_FILL_HOLE_TIME}
                    hideWhenNotLoading
                    classes={{ root: classes.loadingProgressContainer }}
                />
            )}
        </Dialog>
    );
};
