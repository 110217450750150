import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ComputeTaskQuery } from 'src/apiClients/compute/computeApi.types';
import { computeAsyncTask } from 'src/apiClients/compute/utils';
import { TASK_ROOT_TASK } from 'src/constants';

import {
    GtmMeshGetMeshDataAction,
    GtmMeshData,
    GtmGooseContext,
    GtmMeshDataRequestBody,
    GtmMeshDetectorRequestBody,
    GtmMeshDetectionData,
    GtmMeshTransformationRequestBody,
    GtmMeshTransformationResponse,
    GtmObjects,
    GtmMeshDetectorAction,
    GtmMeshTransformationAction,
    GtmMeshDetectorParams,
    GtmMeshTransformationApiResponse,
    GtmMeshTransformationParams,
} from './gtmComputeApi.types';

export const gtmComputeApi = createApi({
    reducerPath: 'gtmTaskApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/' }), // unused when we're using queryFn
    endpoints: (builder) => ({
        gtmMeshData: builder.query<GtmMeshData, ComputeTaskQuery<GtmMeshDataRequestBody>>({
            queryFn: async (query) => {
                const res = await computeAsyncTask(TASK_ROOT_TASK, query);
                if (res.error) return res;
                const taskResult = res.data;
                return {
                    data: {
                        vertices: new Float32Array(JSON.parse(taskResult.points).flat()),
                        triangles: new Int32Array(JSON.parse(taskResult.indices).flat()),
                    },
                };
            },
        }),

        gtmMeshDetector: builder.query<
            GtmMeshDetectionData,
            ComputeTaskQuery<GtmMeshDetectorRequestBody>
        >({
            queryFn: async (query) => {
                const res = await computeAsyncTask(TASK_ROOT_TASK, query);
                if (res.error) return res;

                const taskResult = res.data;
                const arr: string[] = taskResult ? Object.values(taskResult) : [];
                const parsedArr = arr.map((str) => JSON.parse(str));
                return { data: parsedArr };
            },
        }),

        gtmMeshTransformation: builder.query<
            GtmMeshTransformationResponse,
            ComputeTaskQuery<GtmMeshTransformationRequestBody>
        >({
            queryFn: async (query) => {
                const res = await computeAsyncTask(TASK_ROOT_TASK, query);
                if (res.error) return res;

                const taskResult: GtmMeshTransformationApiResponse = res.data;
                if (taskResult) {
                    const parsedObject = Object.entries(taskResult).reduce((acc, [key, value]) => {
                        const object = JSON.parse(value);
                        return {
                            ...acc,
                            [key]: object,
                        };
                    }, {});
                    return { data: parsedObject };
                }
                return { data: {} };
            },
        }),
    }),
});

export const {
    useGtmMeshDataQuery,
    useLazyGtmMeshDataQuery,
    useGtmMeshDetectorQuery,
    useLazyGtmMeshDetectorQuery,
    useGtmMeshTransformationQuery,
    useLazyGtmMeshTransformationQuery,
} = gtmComputeApi;

export const formGtmMeshDataRequestBody = (
    gooseContext: GtmGooseContext,
    objects: GtmObjects,
): ComputeTaskQuery<GtmMeshDataRequestBody> => ({
    orgId: gooseContext.orgId,
    parameters: {
        gooseContext,
        action: GtmMeshGetMeshDataAction.GetMeshData,
        objects,
        params: {},
    },
});

export const formGtmMeshDetectorBody = (
    gooseContext: GtmGooseContext,
    detectorAction: GtmMeshDetectorAction,
    objects: GtmObjects,
    params: GtmMeshDetectorParams,
): ComputeTaskQuery<GtmMeshDetectorRequestBody> => ({
    orgId: gooseContext.orgId,
    parameters: {
        gooseContext,
        action: detectorAction,
        objects,
        params,
    },
});

export const formGtmMeshTransformationBody = (
    gooseContext: GtmGooseContext,
    transformationAction: GtmMeshTransformationAction,
    objects: GtmObjects,
    params: GtmMeshTransformationParams,
): ComputeTaskQuery<GtmMeshTransformationRequestBody> => ({
    orgId: gooseContext.orgId,
    parameters: {
        gooseContext,
        action: transformationAction,
        objects,
        params,
    },
});
