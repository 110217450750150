import { DockablePosition } from '@local/web-design-system/dist/components/DockableContainer';

export interface DockableDialogProps extends DockablePosition {
    onClose: () => void;
}

export enum DockedWindows {
    Slice = 'slice',
    Defects = 'defects',
    Measure = 'measure',
    Settings = 'settings',
    Aggregate = 'aggregate',
}
