import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const styles = (theme: Theme) =>
    ({
        tab: {
            backgroundColor: theme.palette.grey[100],
            textAlign: 'center',
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            '&:first-of-type': {
                borderRight: `1px solid ${theme.palette.grey[300]}`,
            },
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
            cursor: 'pointer',
        },
        tabLabel: {
            fontSize: 12,
            textTransform: 'uppercase',
        },
        tabPanel: {
            height: '100%',
            overflowY: 'hidden',
            position: 'relative',
        },
        selectedTab: {
            backgroundColor: 'white',
            color: theme.palette.primary.main,
            borderBottom: 'none',
        },
    }) as const;

export const useStyles = makeStyles()(styles);
