// Note: This was autogenerated and then edited to remove duplicate interfaces and to re-export from enhancedGooseClient.

import {
    gooseClient as api,
    GeoscienceObject,
    HttpStatus,
    User,
    ObjectResponseLinks,
} from '@local/api-clients/src/goose/enhancedGooseClient';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        updateObjectsById: build.mutation<UpdateObjectsByIdApiResponse, UpdateObjectsByIdApiArg>({
            query: (queryArg) => ({
                url: `/geoscience-object/orgs/${queryArg.orgId}/workspaces/${queryArg.workspaceId}/objects/${queryArg.objectId}`,
                method: 'POST',
                body: queryArg.updateGeoscienceObject,
                headers: { 'If-Match': queryArg['If-Match'] },
            }),
            invalidatesTags: (object) => [
                'Object',
                { type: 'Object', id: object?.object_id || '' },
            ],
        }),
        postObjects: build.mutation<PostObjectsApiResponse, PostObjectsApiArg>({
            query: (queryArg) => ({
                url: `/geoscience-object/orgs/${queryArg.orgId}/workspaces/${queryArg.workspaceId}/objects/path/${queryArg.objectPath}`,
                method: 'POST',
                body: queryArg.geoscienceObject,
                headers: { 'If-Match': queryArg['If-Match'] },
            }),
            invalidatesTags: (object) => [
                'Object',
                { type: 'Object', id: object?.object_id || '' },
            ],
        }),
    }),
    overrideExisting: false,
});

export type UpdateObjectsByIdApiResponse =
    /** status 201 The geoscience object version has been created. */ PostObjectResponse;
export type UpdateObjectsByIdApiArg = {
    /** UUID of a geoscience object. */
    objectId: string;
    /** The customer organisation id. */
    orgId: string;
    workspaceId: string;
    /** Optional header to apply an update _only if_ the specified object version is the most recent available on the server. */
    'If-Match'?: string;
    updateGeoscienceObject: UpdateGeoscienceObject;
};

export type PostObjectsApiResponse =
    /** status 201 The geoscience object version has been created. */ PostObjectResponse;
export type PostObjectsApiArg = {
    /** The customer organisation id. */
    orgId: string;
    workspaceId: string;
    // Added objectPath since the openapi spec doesn't include this.
    objectPath: string;
    /** Optional header to apply an update _only if_ the specified object version is the most recent available on the server. */
    'If-Match'?: string;
    geoscienceObject: GeoscienceObject;
};

export type UpdateGeoscienceObject = {
    schema: string;
    uuid: string;
    [key: string]: any;
};

export type PostObjectResponse = {
    created_at: string;
    created_by?: User | null;
    etag: string;
    links: ObjectResponseLinks;
    object: GeoscienceObject;
    object_id?: string | null;
    object_path?: string | null;
    version_id: string;
};

export type InvalidGeoscienceObject = {
    detail?: string | null;
    json_path?: string;
    schema_path?: any[];
    status?: HttpStatus;
    title?: string;
    type?: string;
};

export { injectedRtkApi as gooseClient };
export * from '@local/api-clients/src/goose/enhancedGooseClient';

export const { useUpdateObjectsByIdMutation, usePostObjectsMutation } = injectedRtkApi;
