/* eslint-disable import/no-cycle */

import { middleware as loginMiddleware, reducers as loginReducers } from '@local/login';
import { workspaceClient } from '@local/workspaces/dist/apiClients/workspaceClient';
import {
    configureStore,
    combineReducers,
    Reducer,
    UnknownAction,
    EnhancedStore,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { fileClient } from 'src/apiClients/file/fileClient';
import { gooseClient } from 'src/apiClients/goose/extendedGooseClient';
import { gtmComputeApi } from 'src/apiClients/gtmCompute/gtmComputeApi';

import { gtmSlice } from './gtm/gtmSlice';
import { projectSlice } from './project/projectSlice';
import { visualizationSlice } from './visualization/visualizationSlice';

const combinedReducer = combineReducers({
    [fileClient.reducerPath]: fileClient.reducer,
    [gtmComputeApi.reducerPath]: gtmComputeApi.reducer,
    [gooseClient.reducerPath]: gooseClient.reducer,
    [visualizationSlice.name]: visualizationSlice.reducer,
    [workspaceClient.reducerPath]: workspaceClient.reducer,
    [gtmSlice.name]: gtmSlice.reducer,
    [projectSlice.name]: projectSlice.reducer,
    ...loginReducers,
});

const rootReducer: Reducer = (state: RootState, action: UnknownAction) => {
    if (action.type === 'store/reset') {
        return {} as RootState;
    }
    return combinedReducer(state, action);
};

export const store: EnhancedStore = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: {
                ignoredPaths: ['ignoredPath', 'ignoredNested.one', 'ignoredNested.two'],
            },
        }).concat(
            fileClient.middleware,
            gtmComputeApi.middleware,
            gooseClient.middleware,
            workspaceClient.middleware,
            ...loginMiddleware,
        ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
