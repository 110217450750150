import { Color as XyzColor } from '@local/webviz/dist/types/xyz';

import { GtmMeshDetectorAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { GtmBounds, GtmColor } from 'src/gtmProject/Project.types';
import * as strings from 'src/strings';
import { BoundingBox } from 'src/visualization/BoundingBoxDialog/BoundingBoxDialog.types';

import { clamp } from './math';

export function boundingBoxToGtmBounds(boundingBox: BoundingBox, boundaryId: string): GtmBounds {
    return {
        minPoint: { x: boundingBox.xMin, y: boundingBox.yMin, z: boundingBox.zMin },
        maxPoint: { x: boundingBox.xMax, y: boundingBox.yMax, z: boundingBox.zMax },
        rotationAngle: 0,
        boundaryId,
    };
}

export function gtmColorToRGBArray(color: GtmColor): XyzColor {
    return [color.r, color.g, color.b];
}

// Assumes alpha is 255 (fully opaque)
export function rgbArrayToGtmColor(rgbArray: number[] | XyzColor): GtmColor {
    return { r: rgbArray[0], g: rgbArray[1], b: rgbArray[2], a: 255 };
}

// Returns a floating-point value on [0.0, 1.0]
export function alphaToOpacity(alpha: number): number {
    return clamp(alpha / 255.0, 0.0, 1.0);
}

// returns an integer value on [0, 255]
export function opacityToAlpha(opacity: number): number {
    return clamp(Math.round(opacity * 255.0), 0, 255);
}

export function detectorActionToDefectString(action: GtmMeshDetectorAction) {
    switch (action) {
        case GtmMeshDetectorAction.DetectDegenerateTris:
            return strings.DEGENERATE_TRIANGLE;
        case GtmMeshDetectorAction.DetectDuplicatePoints:
            return strings.DUPLICATE_POINT;
        case GtmMeshDetectorAction.DetectDuplicateTris:
            return strings.DUPLICATE_TRIANGLE;
        case GtmMeshDetectorAction.DetectFins:
            return strings.FIN;
        case GtmMeshDetectorAction.DetectHoles:
            return strings.HOLE;
        case GtmMeshDetectorAction.DetectSelfIntersections:
            return strings.SELF_INTERSECTION;
        case GtmMeshDetectorAction.DetectNonManifoldEdges:
            return strings.NON_MANIFOLD_EDGE;
        case GtmMeshDetectorAction.DetectNonManifoldVertices:
            return strings.NON_MANIFOLD_VERTEX;
        case GtmMeshDetectorAction.DetectInconsistentlyOrientedTris:
            return strings.INCONSISTENT_TRIANGLE;
        case GtmMeshDetectorAction.DetectNonPartitioningSurfaces:
            return strings.REDUNDANT_SURFACE;
        default:
            return strings.UNKNOWN_DEFECT_TYPE;
    }
}
