import { useTrace } from '@local/web-design-system/dist/utils/trace';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import * as React from 'react';

import { useStyles } from './TabGroup.styles';
import type { TabGroupProps, TabPanelProps } from './TabGroup.types';

export const tagPanelTraceId = 'tab-panel';

export function TabGroup({ className, groups, initialTab, callback }: TabGroupProps) {
    const { classes } = useStyles();
    const [value, setValue] = React.useState(initialTab ?? 0);
    const applyTrace = useTrace('tab-group');

    return (
        <Grid
            item
            container
            className={className}
            direction="column"
            wrap="nowrap"
            alignItems="stretch"
        >
            <Grid item container style={{ width: '100%' }}>
                {groups.map(({ label }, index: number) => (
                    <Grid
                        key={index}
                        xs
                        item
                        className={classnames(classes.tab, {
                            [classes.selectedTab]: value === index,
                        })}
                        onClick={() => {
                            callback?.(index);
                            setValue(index);
                        }}
                        {...applyTrace(label)}
                    >
                        <Typography className={classes.tabLabel}>{label}</Typography>
                    </Grid>
                ))}
            </Grid>
            {groups.map(({ panel }, index: number) => (
                <TabPanel key={index} value={value} index={index}>
                    {panel}
                </TabPanel>
            ))}
        </Grid>
    );
}

function TabPanel({ children, value, index }: TabPanelProps) {
    const { classes } = useStyles();
    const applyTrace = useTrace(tagPanelTraceId);
    const display = value !== index ? 'none' : undefined;
    return (
        <Grid className={classes.tabPanel} style={{ display }} {...applyTrace(`${index}`)}>
            {children}
        </Grid>
    );
}

export default TabGroup;
