import { useMessagesContext } from '@local/messages/dist/MessagesContext';
import { trackError } from '@local/metrics/dist/src/metrics';
import { WDSThemeProvider } from '@local/web-design-system-2';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';
import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import { useSelection } from '@local/webviz/dist/context/hooks/useSelection';
import { CameraState, Nullable, PlotState } from '@local/webviz/dist/types/xyz';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Stack from '@mui/material/Stack';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import round from 'lodash-es/round';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { useCustomUpsertFileByPathMutation } from 'src/apiClients/file/customFileEndpoints';
import { useLazyListObjectsQuery, ListedObject } from 'src/apiClients/goose/extendedGooseClient';
import {
    formGtmMeshTransformationBody,
    useLazyGtmMeshTransformationQuery,
} from 'src/apiClients/gtmCompute/gtmComputeApi';
import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { AGGREGATE_GEOMETRY_NAME, GTM_FOLDER_PREFIX, MESH_SCHEMA } from 'src/constants';
import { WDS2ThemeContext } from 'src/context/ThemeContext/ThemeContext';
import {
    GtmProject,
    GtmEvoFileType,
    GtmEvoOutputObject,
    GtmProjectInput,
} from 'src/gtmProject/Project.types';
import { useLazyInitAggregate } from 'src/hooks/aggregation/useLazyInitAggregate';
import { useGooseContext } from 'src/hooks/useGooseContext';
import { useAppSelector } from 'src/store/store';
import { sceneObjectMap } from 'src/store/visualization/selectors';
import { ERROR_CREATING_PROJECT, PROJECT_EXTENSION } from 'src/strings';
import { boundingBoxToGtmBounds, rgbArrayToGtmColor } from 'src/utils/typeTransformations';
import { initialColorGenerator } from 'src/visualization/context/generateData';
import { TransformationProgressModal } from 'src/visualization/TransformationProgressModal/TransformationProgressModal';
import {
    START_UPLOAD_MESSAGE,
    UPLOAD_SUCCESS_MESSAGE,
} from 'src/visualization/TransformationProgressModal/TransformationProgressModal.constants';

import {
    BOUNDING_BOX_TITLE,
    NAME_TITLE,
    BOUNDING_BOX_DEFAULT_NAME,
    MAX_TITLE,
    MIN_TITLE,
    X_LABEL,
    Y_LABEL,
    Z_LABEL,
    NO_SELECTION_TEXT,
    ACCEPT,
    CANCEL,
    ERROR_MAX_MUST_GREATER_THAN_MIN,
    BOX_VALUE_PRECISION,
    ENCLOSE_ENTIRE_MODEL,
    START_ANALYTICAL_BOUNDARY_CREATION_MESSAGE,
    ANALYTICAL_BOUNDARY_CREATION_SUCCESS_MESSAGE,
    INITIALIZE_AGGREGATE_GEOM_MESSAGE,
    AGGREGATE_GEOM_INITIALIZE_SUCCESS_MESSAGE,
} from './BoundingBoxDialog.constants';
import {
    BoundingBox,
    BoundingBoxDialogErrorState,
    BoundingBoxDialogProps,
    BoundingBoxErrorState,
    Point,
} from './BoundingBoxDialog.types';
import {
    computeBoundingBoxFromCenter,
    computeBoundingBoxVertices,
    getBoundingBoxSnapshot,
} from './snapshot';

const defaultErrorState: BoundingBoxDialogErrorState = {
    minErrorState: {
        isErrorX: false,
        isErrorY: false,
        isErrorZ: false,
    },
    maxErrorState: {
        isErrorX: false,
        isErrorY: false,
        isErrorZ: false,
    },
};

function isBoundingBoxError(errorState: BoundingBoxDialogErrorState): boolean {
    const { minErrorState, maxErrorState } = errorState;
    return (
        minErrorState.isErrorX ||
        minErrorState.isErrorY ||
        minErrorState.isErrorZ ||
        maxErrorState.isErrorX ||
        maxErrorState.isErrorY ||
        maxErrorState.isErrorZ
    );
}

function getPlotBoundingBox(getEntityState: (entityName: string) => any): BoundingBox | undefined {
    const plot = getEntityState('plot') as any;
    const { boundingBox } = plot;
    if (boundingBox) {
        return {
            xMin: boundingBox.min[0],
            xMax: boundingBox.max[0],
            yMin: boundingBox.min[1],
            yMax: boundingBox.max[1],
            zMin: boundingBox.min[2],
            zMax: boundingBox.max[2],
        };
    }
    return undefined;
}

function detectBoundingBoxError(boundingBox: BoundingBox): BoundingBoxDialogErrorState {
    const { xMin, yMin, zMin, xMax, yMax, zMax } = boundingBox;

    const minErrorState = {
        isErrorX: !Number.isFinite(xMin) || xMax <= xMin,
        isErrorY: !Number.isFinite(yMin) || yMax <= yMin,
        isErrorZ: !Number.isFinite(zMin) || zMax <= zMin,
    };
    const maxErrorState = {
        isErrorX: !Number.isFinite(xMax) || xMax <= xMin,
        isErrorY: !Number.isFinite(yMax) || yMax <= yMin,
        isErrorZ: !Number.isFinite(zMax) || zMax <= zMin,
    };
    return {
        minErrorState,
        maxErrorState,
    };
}

export function BoundingBoxDialog({
    existingProjectNames,
    onClose,
    onBoundaryCreated,
}: Readonly<BoundingBoxDialogProps>) {
    const sceneObjects = useAppSelector(sceneObjectMap);
    const params = useParams();
    const { getEntityState, setStateFromSnapshot } = useBaseXyz();
    const { selectionState, unselect } = useSelection();
    const gooseContext = useGooseContext();
    const organisationId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);

    const [updateFile] = useCustomUpsertFileByPathMutation();
    const [GtmMeshTransformationTrigger] = useLazyGtmMeshTransformationQuery();
    const [InitAggregateTrigger] = useLazyInitAggregate();
    const [GooseListObjectsTrigger] = useLazyListObjectsQuery();

    const [boxId, setBoxId] = useState<Nullable<string>>(null);
    const [boundaryName, setBoundaryName] = useState<string>(BOUNDING_BOX_DEFAULT_NAME);
    const [boundingBox, setBoundingBox] = useState<BoundingBox | undefined>(undefined);
    const [modalMessage, setModalMessage] = useState<string>('');
    const [isClipping, setIsClipping] = useState<boolean>(false);

    const [isEntireDomainBounded, setIsEntireDomainBounded] = useState<boolean>(false);

    const [errorState, setErrorState] = useState<BoundingBoxDialogErrorState>(defaultErrorState);

    const { addMessage } = useMessagesContext();
    const { theme: appTheme } = useContext(WDS2ThemeContext);

    const updateBoundingBoxIfItExists = useCallback(
        (updatedValue: Partial<BoundingBox>) => {
            if (boundingBox && updatedValue) {
                const updatedBoundingBox = { ...boundingBox, ...updatedValue };
                const newErrorState = detectBoundingBoxError(updatedBoundingBox);
                setErrorState(newErrorState);
                setBoundingBox((currentBoundingBox) => ({
                    ...currentBoundingBox!,
                    ...updatedValue,
                }));
            }
        },
        [boundingBox],
    );

    function removeBoundingBoxIfItExists() {
        if (boundingBox) {
            // Don't use the captured 'views'. It may have changed in the time between the click and the close
            // ie: the event handler.
            const existingViews = (getEntityState('plot') as PlotState).views;
            // Remove the box from the plot views. Note that it is not necessarily in a particular
            // position in the array.
            existingViews.splice(existingViews.indexOf(boxId!), 1);
            setStateFromSnapshot({ plot: { views: existingViews } }, {});
            setBoundingBox(undefined);
        }
    }

    function closeDialog() {
        removeBoundingBoxIfItExists();
        onClose();
    }

    async function uploadNewProject(project: GtmProject) {
        const fileName = `${project.name}.${PROJECT_EXTENSION}`;
        return updateFile({
            workspaceId,
            organisationId,
            filePath: fileName,
            uploadFile: new File(
                [new Blob([JSON.stringify(project)], { type: 'application/json' })],
                fileName,
            ),
        });
    }

    async function handleError(error: unknown, projectName: string) {
        addMessage({
            message: ERROR_CREATING_PROJECT,
            type: NotificationType.ERROR,
        });
        trackError(`Error: ${error} creating project "${projectName}"`);
        return Promise.reject(error);
    }

    async function makeOutputObjectsFromCreated(
        objects: GtmProjectInput[],
    ): Promise<GtmEvoOutputObject[]> {
        // We need the objects' name which we don't have, so we have to list to get it :(
        const { data: objectListing, isError } = await GooseListObjectsTrigger({
            orgId: organisationId,
            workspaceId,
            objectName: [`ilike:${GTM_FOLDER_PREFIX}/${boundaryName}*`],
        });

        if (isError || !objectListing) {
            return Promise.reject(new Error('Error listing objects for metadata generation'));
        }

        try {
            const outputObjects = objectListing.objects.map((object: ListedObject) => {
                const createdObject = objects.find((obj) => obj.object_id === object.object_id);
                if (!createdObject) {
                    throw new Error(
                        `Error: created object with id '${object.object_id}' not found`,
                    );
                }

                return {
                    type: GtmEvoFileType.GeoscienceObject,
                    name: object.name,
                    id: createdObject.object_id,
                    version_id: createdObject.version_id,
                    schema: object.schema,
                    color: rgbArrayToGtmColor(initialColorGenerator()),
                    is_aggregated: false,
                };
            });
            return outputObjects;
        } catch (error) {
            return await handleError(error, boundaryName);
        }
    }

    function createNewProject(
        projectName: string,
        inputObjects: GtmProjectInput[],
        objects: GtmEvoOutputObject[],
        boundaryId: string,
    ) {
        const project: GtmProject = {
            name: projectName,
            inputs: inputObjects,
            analytical_models: [
                {
                    bounds: boundingBoxToGtmBounds(boundingBox!, boundaryId),
                    objects,
                },
            ],
            history: [],
        };

        return project;
    }

    async function createAnalyticalBoundary(
        inputObjects: GtmProjectInput[],
    ): Promise<{ projectObjects: GtmProjectInput[]; boundaryId: string }> {
        if (!gooseContext) {
            // Shouldn't actually happen since we have an org route guard.
            trackError('Error: No goose context');
            throw new Error('No goose context');
        }

        const boundaryId = uuidv4();

        const creationParams = {
            ...boundingBoxToGtmBounds(boundingBox!, boundaryId),
            // In the future we should have a boundary name and a project name.
            // but at the moment we create a project with the boundary name.
            projectName: boundaryName,
        };

        const clipToBoundaryPromises = inputObjects.map(({ object_id, version_id }) => {
            const body = formGtmMeshTransformationBody(
                gooseContext,
                GtmMeshTransformationAction.CreateAnalyticalBoundary,
                [{ id: object_id, version: version_id }],
                creationParams,
            );
            return GtmMeshTransformationTrigger(body);
        });

        const clipToBoundaryResults = await Promise.all(clipToBoundaryPromises);

        if (clipToBoundaryResults.some(({ isError }) => isError)) {
            // Probably shouldn't error out completely.
            // Could report which surface failed.
            return Promise.reject(new Error('Error creating the analytical boundary.'));
        }

        const createdObjects = clipToBoundaryResults.map(({ data }) => data?.created || []).flat();

        if (createdObjects.length) {
            return {
                projectObjects: createdObjects.map(({ id, version }) => ({
                    object_id: id,
                    version_id: version,
                })),
                boundaryId,
            };
        }

        return Promise.reject(
            new Error(
                'Analytical boundary creation succeeded but no objects are within the bounds.',
            ),
        );
    }

    async function InitAggregate(boundaryId: string): Promise<GtmProjectInput> {
        try {
            const { initResult: result, initIsError: isError } = await InitAggregateTrigger(
                boundingBoxToGtmBounds(boundingBox!, boundaryId),
            );

            if (isError) {
                throw new Error('Error making aggregate geometry');
            }

            if (result?.created.length) {
                const object = result.created[0];
                return {
                    object_id: object.id,
                    version_id: object.version,
                };
            }

            throw new Error('No object created');
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async function updateAggregateGeom(project: GtmProject) {
        const updatedProject = project;

        const { object_id: aggregateId, version_id: aggregateVersion } = await InitAggregate(
            project.analytical_models[0].bounds.boundaryId,
        );

        // This is a kludge to get a default color for us devs.
        const initialColor = initialColorGenerator();

        updatedProject.analytical_models[0].composite_model = {
            name: AGGREGATE_GEOMETRY_NAME,
            id: aggregateId,
            version_id: aggregateVersion,
            type: GtmEvoFileType.GeoscienceObject,
            schema: MESH_SCHEMA,
            color: rgbArrayToGtmColor(initialColor),
        };

        return updatedProject;
    }

    async function handleCreateBoundary() {
        // TODO: GEOM-108 - Validate the bounds
        const { views } = getEntityState('plot') as any;
        const inputObjects: GtmProjectInput[] = Object.keys(sceneObjects)
            .filter((objectId) => views.includes(objectId))
            .map((objectId) => {
                const sceneObject = sceneObjects[objectId];
                return {
                    object_id: sceneObject.objectId,
                    version_id: sceneObject.versionId,
                };
            });

        try {
            setIsClipping(true);

            setModalMessage(START_ANALYTICAL_BOUNDARY_CREATION_MESSAGE);

            const { projectObjects: objectsInBoundary, boundaryId } =
                await createAnalyticalBoundary(inputObjects);

            if (objectsInBoundary) {
                const projectObjects = await makeOutputObjectsFromCreated(objectsInBoundary);
                let project = createNewProject(
                    boundaryName,
                    inputObjects,
                    projectObjects,
                    boundaryId,
                );

                setModalMessage(
                    `${ANALYTICAL_BOUNDARY_CREATION_SUCCESS_MESSAGE} ${INITIALIZE_AGGREGATE_GEOM_MESSAGE}`,
                );
                project = await updateAggregateGeom(project);

                setModalMessage(
                    `${AGGREGATE_GEOM_INITIALIZE_SUCCESS_MESSAGE} ${START_UPLOAD_MESSAGE}`,
                );
                const uploadResponse = await uploadNewProject(project);
                if (uploadResponse.data) {
                    onBoundaryCreated(uploadResponse.data);
                    setModalMessage(UPLOAD_SUCCESS_MESSAGE);
                }
            }
        } catch (error) {
            await handleError(error, boundaryName);
        } finally {
            setIsClipping(false);
        }

        closeDialog();
    }

    useEffect(() => {
        if (selectionState?.position && !isEntireDomainBounded) {
            removeBoundingBoxIfItExists();
            const randomBoxId = Math.floor(Math.random() * 100).toString(); // Random number to create a unique box id (temporary solution)
            setBoxId(randomBoxId);
            const { radius } = getEntityState('camera') as CameraState;
            const box = computeBoundingBoxFromCenter(selectionState.position, radius / 10);
            setBoundingBox(box);
            const { views } = getEntityState('plot') as any;
            const snapshot = getBoundingBoxSnapshot({ label: randomBoxId!, box });
            const plotViewSnapshot = {
                ...snapshot,
                plot: { views: [...views, randomBoxId] },
            };
            if (!plotViewSnapshot) {
                return;
            }
            setStateFromSnapshot(plotViewSnapshot, {});
        } else {
            unselect();
        }
    }, [selectionState, isEntireDomainBounded]);

    useEffect(() => {
        if (boundingBox) {
            const boundingVertices = computeBoundingBoxVertices(boundingBox);
            const elementId = `bounding-box-${boxId}`;
            const snapshot = { [elementId]: { vertices: boundingVertices } };
            setStateFromSnapshot(snapshot, {});
        }
    }, [boundingBox]);

    const projectNameExists = existingProjectNames.includes(boundaryName);
    const isBoundingError = isBoundingBoxError(errorState);
    return (
        <WDSThemeProvider themeMode={appTheme}>
            <DialogHeader closeDialog={closeDialog} />

            {!boundingBox && (
                <Typography variant="body2" align="center">
                    {NO_SELECTION_TEXT}
                </Typography>
            )}

            {isBoundingError && boundingBox && (
                <Alert
                    severity="error"
                    sx={{
                        marginLeft: (theme) => theme.spacing(2),
                        marginRight: (theme) => theme.spacing(2),
                        marginBottom: (theme) => theme.spacing(2),
                        marginTop: (theme) => theme.spacing(1),
                    }}
                >
                    {ERROR_MAX_MUST_GREATER_THAN_MIN}
                </Alert>
            )}

            {boundingBox && (
                <>
                    <BoundaryNameSection
                        isCurrentTextValid={!projectNameExists}
                        onChange={(event) => setBoundaryName(event.target.value)}
                    />
                    <EncloseModelCheckbox
                        onChange={updateBoundingBoxIfItExists}
                        isEntireDomainBounded={isEntireDomainBounded}
                        setIsEntireDomainBounded={setIsEntireDomainBounded}
                    />
                    <CoordinatesInputSection
                        boundingBox={boundingBox}
                        onChange={updateBoundingBoxIfItExists}
                        boundingErrorState={errorState}
                        isEntireDomainBounded={isEntireDomainBounded}
                    />
                    <ButtonsSection
                        isCreateDisabled={projectNameExists || isBoundingError}
                        onCreateBoundary={handleCreateBoundary}
                        onDeleteBoundary={closeDialog}
                    />
                </>
            )}

            <TransformationProgressModal open={isClipping} dialogContent={modalMessage} />
        </WDSThemeProvider>
    );
}

function DialogHeader({ closeDialog }: { closeDialog: () => void }) {
    return (
        <ListItem>
            <ListItemText secondary={BOUNDING_BOX_TITLE} sx={{ textTransform: 'uppercase' }} />
            <IconButton onClick={closeDialog}>
                <CloseIcon />
            </IconButton>
        </ListItem>
    );
}

function OutlinedTextFieldSizedTextProps(
    themeSpacing: number,
): Pick<TextFieldProps, 'InputProps' | 'InputLabelProps'> {
    return {
        InputProps: {
            sx: {
                fontSize: (theme) => theme.spacing(themeSpacing),
            },
        },
        InputLabelProps: {
            sx: { fontSize: (theme) => theme.spacing(themeSpacing) },
        },
    };
}

function BoundaryNameSection({
    isCurrentTextValid,
    onChange,
}: {
    isCurrentTextValid: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
    return (
        <TextField
            size="small"
            label={NAME_TITLE}
            variant="outlined"
            onChange={onChange}
            defaultValue={BOUNDING_BOX_DEFAULT_NAME}
            error={!isCurrentTextValid}
            helperText={isCurrentTextValid ? undefined : 'The boundary name already exists'}
            sx={{
                marginLeft: (theme) => theme.spacing(2),
            }}
            {...OutlinedTextFieldSizedTextProps(1.75)}
        />
    );
}

interface CoordinatesInputSectionProps {
    boundingBox: BoundingBox;
    onChange: (updatedValue: Partial<BoundingBox>) => void;
    boundingErrorState: BoundingBoxDialogErrorState;
    isEntireDomainBounded: boolean;
}

function CoordinatesInputSection({
    boundingBox,
    onChange,
    boundingErrorState,
    isEntireDomainBounded,
}: Readonly<CoordinatesInputSectionProps>) {
    return (
        <>
            <BoundingPointInputs
                label={MIN_TITLE}
                boxValues={{ x: boundingBox.xMin, y: boundingBox.yMin, z: boundingBox.zMin }}
                onChange={onChange}
                errorState={boundingErrorState.minErrorState}
                maxOrMin="Min"
                isDisabled={isEntireDomainBounded}
            />
            <BoundingPointInputs
                label={MAX_TITLE}
                boxValues={{ x: boundingBox.xMax, y: boundingBox.yMax, z: boundingBox.zMax }}
                onChange={onChange}
                errorState={boundingErrorState.maxErrorState}
                maxOrMin="Max"
                isDisabled={isEntireDomainBounded}
            />
        </>
    );
}

type MaxOrMin = 'Min' | 'Max';
type Axis = 'x' | 'y' | 'z';

interface BoundingPointInputsProps {
    label: Readonly<string>;
    boxValues: Readonly<Point>;
    onChange: (updatedValue: Partial<BoundingBox>) => void;
    errorState: Readonly<BoundingBoxErrorState>;
    maxOrMin: MaxOrMin;
    isDisabled: boolean;
}

function BoundingPointInputs({
    label,
    boxValues,
    onChange,
    errorState,
    maxOrMin,
    isDisabled,
}: Readonly<BoundingPointInputsProps>) {
    const { x, y, z } = boxValues;

    const [xText, setXText] = useState(round(x, BOX_VALUE_PRECISION).toString());
    const [yText, setYText] = useState(round(y, BOX_VALUE_PRECISION).toString());
    const [zText, setZText] = useState(round(z, BOX_VALUE_PRECISION).toString());

    function setText(axis: Axis, value: string) {
        switch (axis) {
            case 'x':
                setXText(value);
                break;
            case 'y':
                setYText(value);
                break;
            case 'z':
                setZText(value);
                break;
            default:
                break;
        }
    }

    function handleChange(
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        axis: Axis,
    ) {
        setText(axis, event.target.value);
    }

    function changeBoxOnEvent(axis: Axis, value: string) {
        const parsedValue = parseFloat(value);
        onChange({ [`${axis}${maxOrMin}`]: Number.isFinite(parsedValue) ? parsedValue : NaN });
        setText(axis, value);
    }

    function handleBlur(
        event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>,
        axis: Axis,
    ) {
        changeBoxOnEvent(axis, event.target.value);
    }

    function handleKeyUp(event: React.KeyboardEvent<HTMLInputElement>, axis: Axis) {
        if (event.key === 'Enter') {
            changeBoxOnEvent(axis, event.currentTarget.value);
        }
    }

    const textFieldProps = OutlinedTextFieldSizedTextProps(1.75);
    textFieldProps.InputProps = {
        ...textFieldProps.InputProps,
        onKeyUp: (event: React.KeyboardEvent<HTMLInputElement>) => {
            handleKeyUp(event, 'x');
        },
    };

    useEffect(() => {
        setXText(Number.isFinite(x) ? round(x, BOX_VALUE_PRECISION).toString() : xText);
        setYText(Number.isFinite(y) ? round(y, BOX_VALUE_PRECISION).toString() : yText);
        setZText(Number.isFinite(z) ? round(z, BOX_VALUE_PRECISION).toString() : zText);
    }, [x, y, z]);

    return (
        <>
            <ListSubheader>{label}</ListSubheader>
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    marginLeft: (theme) => theme.spacing(2),
                    marginRight: (theme) => theme.spacing(2),
                }}
            >
                <TextField
                    type="text"
                    inputMode="numeric"
                    disabled={isDisabled}
                    size="small"
                    variant="standard"
                    error={errorState.isErrorX}
                    value={xText}
                    label={X_LABEL}
                    onChange={(
                        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                    ) => {
                        handleChange(event, 'x');
                    }}
                    onBlur={(event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        handleBlur(event, 'x');
                    }}
                    {...textFieldProps}
                />
                <TextField
                    type="text"
                    inputMode="numeric"
                    disabled={isDisabled}
                    size="small"
                    variant="standard"
                    error={errorState.isErrorY}
                    label={Y_LABEL}
                    value={yText}
                    onChange={(
                        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                    ) => {
                        handleChange(event, 'y');
                    }}
                    onBlur={(event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        handleBlur(event, 'y');
                    }}
                    {...textFieldProps}
                />
                <TextField
                    type="text"
                    inputMode="numeric"
                    disabled={isDisabled}
                    size="small"
                    variant="standard"
                    error={errorState.isErrorZ}
                    label={Z_LABEL}
                    value={zText}
                    onChange={(
                        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                    ) => {
                        handleChange(event, 'z');
                    }}
                    onBlur={(event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        handleBlur(event, 'z');
                    }}
                    {...textFieldProps}
                />
            </Stack>
        </>
    );
}

function ButtonsSection({
    isCreateDisabled,
    onCreateBoundary,
    onDeleteBoundary,
}: {
    isCreateDisabled: boolean;
    onCreateBoundary: () => Promise<void>;
    onDeleteBoundary: () => void;
}) {
    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{
                paddingTop: (theme) => theme.spacing(1.5),
                paddingBottom: (theme) => theme.spacing(1.5),
                paddingLeft: (theme) => theme.spacing(2),
                paddingRight: (theme) => theme.spacing(2),
            }}
        >
            <Button
                size="small"
                color="primary"
                variant="outlined"
                fullWidth
                onClick={onDeleteBoundary}
            >
                {CANCEL}
            </Button>
            <Button
                size="small"
                color="primary"
                variant="contained"
                fullWidth
                onClick={onCreateBoundary}
                disabled={isCreateDisabled}
            >
                {ACCEPT}
            </Button>
        </Stack>
    );
}

interface EncloseModelCheckboxProps {
    onChange: (updatedValue: Partial<BoundingBox>) => void;
    isEntireDomainBounded: boolean;
    setIsEntireDomainBounded: (value: boolean) => void;
}

function EncloseModelCheckbox({
    onChange,
    isEntireDomainBounded,
    setIsEntireDomainBounded: setEntireDomainBounded,
}: Readonly<EncloseModelCheckboxProps>) {
    const { getEntityState } = useBaseXyz();
    const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEntireDomainBounded(event.target.checked);
        if (event.target.checked) {
            const boundingBox = getPlotBoundingBox(getEntityState);
            if (boundingBox) {
                onChange(boundingBox);
            }
        }
    };

    const checkboxControl = (
        <Checkbox
            color="primary"
            size="medium"
            onChange={onCheckboxChange}
            checked={isEntireDomainBounded}
        />
    );

    return (
        <FormControlLabel
            control={checkboxControl}
            labelPlacement="end"
            label={ENCLOSE_ENTIRE_MODEL}
            sx={{ marginLeft: (theme) => theme.spacing(1) }}
        />
    );
}
