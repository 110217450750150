import { Dialog } from '@local/web-design-system/dist/components/Dialog';
import { NumericInput } from '@local/web-design-system/dist/components/NumericInput';
import { LinearProgressFaked } from '@local/web-design-system/dist/components/Progress/LinearProgressFaked';
import Typography from '@mui/material/Typography';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    formGtmMeshTransformationBody,
    useLazyGtmMeshTransformationQuery,
} from 'src/apiClients/gtmCompute/gtmComputeApi';
import {
    GtmMeshTransformationAction,
    GtmMeshRemoveDegenerateTriangleParams,
    ObjectIdWithVersion,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { GtmEvoOutputObject, GtmProject } from 'src/gtmProject/Project.types';
import {
    DEFAULT_DETECTOR_PARAMS,
    useDefectsLoadingManager,
} from 'src/hooks/defects/useDefectsLoadingManager';
import { useProjectManager } from 'src/hooks/project';
import { useGooseContext } from 'src/hooks/useGooseContext';
import { useSceneObjectDataManager } from 'src/hooks/useSceneObjectDataManager';
import {
    selectCurrentProjectData,
    selectCurrentAnalyticalModelIndex,
    selectCurrentProjectVersionId,
} from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { selectedSceneObjects } from 'src/store/visualization/selectors';
import { makeHistoryEntry } from 'src/utils/history/history';
import { summarizeTransformationActionHistoryOperation } from 'src/utils/history/historySummary';
import { setTransformationParam, TransformationParam } from 'src/utils/tranformationParamStorage';

import { ParameterRow } from './ParameterRow/ParameterRow';
import {
    REMOVE_DEGENERATE_TRIANGLES,
    REMOVE,
    CANCEL,
    NEEDLE_THRESHOLD_RATIO,
    NEEDLE_THRESHOLD_ONE_TO,
    NEEDLE_THRESHOLD_RATIO_INFO,
    NEEDLE_COLLAPSE_LENGTH,
    CAP_MINIMUM_ANGLE,
    CAP_MINIMUM_ANGLE_INFO,
    NEEDLE_COLLAPSE_LENGTH_INFO,
    APPROXIMATE_DEGENERATE_TRIANGLE_REMOVAL_TIME,
} from './RemoveDegenerateTriangles.constants';
import { useStyles } from './RemoveDegenerateTriangles.styles';

const NumericInputParameterRow = ({
    PrefixElements,
    value,
    onChange,
    tooltipText,
    min,
    exclusiveMin,
    max,
    exclusiveMax,
}: {
    PrefixElements: any;
    value: number;
    onChange: (value: number) => void;
    tooltipText: string;
    min?: number;
    exclusiveMin?: boolean;
    max?: number;
    exclusiveMax?: boolean;
}) => {
    const { classes } = useStyles();
    return (
        <ParameterRow
            ParameterElements={() => (
                <>
                    <PrefixElements />
                    <NumericInput
                        value={value}
                        onChange={(newValue) => onChange(newValue)}
                        min={min}
                        exclusiveMin={exclusiveMin}
                        max={max}
                        exclusiveMax={exclusiveMax}
                        arrows
                        className={classes.numericInput}
                    />
                </>
            )}
            tooltipText={tooltipText}
        />
    );
};

export const RemoveDegenerateTrianglesDialog = ({
    open,
    onClose,
}: {
    open: boolean;
    onClose: () => void;
}) => {
    const { classes } = useStyles();

    const gooseContext = useGooseContext();
    const selectedObjects = useAppSelector(selectedSceneObjects);
    const [GtmMeshTransformationTrigger] = useLazyGtmMeshTransformationQuery();
    const projectJson = useAppSelector(selectCurrentProjectData);
    const currentAnalyticalModelIndex = useAppSelector(selectCurrentAnalyticalModelIndex);
    const currentProjectVersionId = useAppSelector(selectCurrentProjectVersionId);
    const { removeGtmObject, loadGtmObject } = useSceneObjectDataManager();
    const { runAllDetectors } = useDefectsLoadingManager();
    const { uploadProject } = useProjectManager();
    const { projectName } = useParams();

    const [needleThresholdRatio, setNeedleThresholdRatio] = useState<number>(
        DEFAULT_DETECTOR_PARAMS.needleThresholdRatio,
    );
    const [needleCollapseLength, setNeedleCollapseLength] = useState<number>(
        DEFAULT_DETECTOR_PARAMS.needleCollapseLength,
    );
    const [capMinimumAngleInDegrees, setCapMinimumAngleInDegrees] = useState<number>(
        DEFAULT_DETECTOR_PARAMS.capMinAngleDegrees,
    );
    const [isTransformationRunning, setIsTransformationRunning] = useState<boolean>(false);

    const removeDegenerateTriangles = useCallback(
        async (object: ObjectIdWithVersion, params: GtmMeshRemoveDegenerateTriangleParams) => {
            const { data: result } = await GtmMeshTransformationTrigger(
                formGtmMeshTransformationBody(
                    gooseContext!,
                    GtmMeshTransformationAction.RemoveDegenerateTris,
                    [object],
                    params,
                ),
            );
            return result;
        },
        [gooseContext, GtmMeshTransformationTrigger],
    );

    const removeDegenerateTrianglesForAllSelectedObjects = useCallback(
        async (params: GtmMeshRemoveDegenerateTriangleParams) => {
            let updatedObjects: Record<string, string> = {};
            return {
                loadObjectPromises: await Promise.all(
                    Object.entries(selectedObjects).map(async ([objectId, artifact]) => {
                        const result = await removeDegenerateTriangles(
                            { id: objectId, version: artifact.versionId },
                            params,
                        );
                        if (result) {
                            updatedObjects = {
                                ...updatedObjects,
                                [result!.modified[0].id]: result!.modified[0].version,
                            };
                            await removeGtmObject(result!.modified[0].id);
                            await loadGtmObject(
                                result!.modified[0].id,
                                result!.modified[0].version,
                            );
                            const gtmOutput = {
                                id: result!.modified[0].id,
                                version_id: result!.modified[0].version,
                                name: artifact.name,
                            } as GtmEvoOutputObject;
                            await runAllDetectors(gtmOutput);
                        }
                    }),
                ),
                updatedObjects,
            };
        },
        [selectedObjects, removeDegenerateTriangles],
    );

    const action = useCallback(
        async (accept: boolean | null) => {
            if (accept) {
                setTransformationParam(
                    TransformationParam.needleThresholdRatio,
                    needleThresholdRatio,
                );
                setTransformationParam(
                    TransformationParam.needleCollapseLength,
                    needleCollapseLength,
                );
                setTransformationParam(
                    TransformationParam.capMinimumAngleInDegrees,
                    capMinimumAngleInDegrees,
                );
                setIsTransformationRunning(true);
                const params = {
                    needleThresholdRatio,
                    capMinAngleDegrees: capMinimumAngleInDegrees,
                    needleCollapseLength,
                };
                const { loadObjectPromises, updatedObjects } =
                    await removeDegenerateTrianglesForAllSelectedObjects(params);

                await Promise.all(loadObjectPromises);

                if (projectJson && projectName) {
                    const updatedProjectJson: GtmProject = JSON.parse(JSON.stringify(projectJson));

                    Object.entries(updatedObjects).forEach(([objectId, updatedVersion]) => {
                        const index = updatedProjectJson.analytical_models[
                            currentAnalyticalModelIndex
                        ].objects.findIndex((input: GtmEvoOutputObject) => input.id === objectId);
                        if (index !== -1) {
                            updatedProjectJson.analytical_models[
                                currentAnalyticalModelIndex
                            ].objects[index].version_id = updatedVersion;
                        }
                    });

                    const newEntry = makeHistoryEntry(
                        summarizeTransformationActionHistoryOperation(
                            GtmMeshTransformationAction.RemoveDegenerateTris,
                            params,
                        ),
                        currentProjectVersionId,
                    );
                    updatedProjectJson.history = [newEntry, ...(updatedProjectJson.history || [])];

                    uploadProject(updatedProjectJson, projectName);
                }
                setIsTransformationRunning(false);
            }
            onClose();
        },
        [
            removeDegenerateTrianglesForAllSelectedObjects,
            needleThresholdRatio,
            capMinimumAngleInDegrees,
            needleCollapseLength,
            currentProjectVersionId,
        ],
    );

    return (
        <Dialog
            action={(response) => {
                action(response);
            }}
            dialogTitle={REMOVE_DEGENERATE_TRIANGLES}
            confirmText={REMOVE}
            cancelText={CANCEL}
            open={open}
            displayActions={!isTransformationRunning}
        >
            <NumericInputParameterRow
                PrefixElements={() => (
                    <>
                        <Typography className={classes.label}>{NEEDLE_THRESHOLD_RATIO}</Typography>
                        <Typography className={classes.oneTo}>{NEEDLE_THRESHOLD_ONE_TO}</Typography>
                    </>
                )}
                value={needleThresholdRatio}
                onChange={setNeedleThresholdRatio}
                min={0}
                exclusiveMin
                tooltipText={NEEDLE_THRESHOLD_RATIO_INFO}
            />
            <NumericInputParameterRow
                PrefixElements={() => (
                    <Typography className={classes.label}>{NEEDLE_COLLAPSE_LENGTH}</Typography>
                )}
                value={needleCollapseLength}
                onChange={setNeedleCollapseLength}
                min={0}
                tooltipText={NEEDLE_COLLAPSE_LENGTH_INFO}
            />
            <NumericInputParameterRow
                PrefixElements={() => (
                    <Typography className={classes.label}>{CAP_MINIMUM_ANGLE}</Typography>
                )}
                value={capMinimumAngleInDegrees}
                onChange={setCapMinimumAngleInDegrees}
                min={90}
                max={180}
                exclusiveMax
                tooltipText={CAP_MINIMUM_ANGLE_INFO}
            />
            {isTransformationRunning && (
                <LinearProgressFaked
                    isLoading={isTransformationRunning}
                    milliseconds={APPROXIMATE_DEGENERATE_TRIANGLE_REMOVAL_TIME}
                    hideWhenNotLoading
                    classes={{ root: classes.loadingProgressContainer }}
                />
            )}
        </Dialog>
    );
};
