export const DEFECT_PREFIX = 'defect';
export const HIGHLIGHT_SUFFIX = 'highlight';
export const ELEMENT_SUFFIX = 'element';

export function toDefectViewId(objectId: string, defectName: string, defectIndex: number) {
    return `${DEFECT_PREFIX}-${objectId}-${defectName}-${defectIndex}`;
}

export function toHighlightViewId(objectId: string, defectName: string, defectIndex: number) {
    return `${toDefectViewId(objectId, defectName, defectIndex)}-${HIGHLIGHT_SUFFIX}`;
}

export function toElementViewId(objectId: string, defectName: string, defectIndex: number) {
    return `${toDefectViewId(objectId, defectName, defectIndex)}-${ELEMENT_SUFFIX}`;
}
