import { useZIndexContext, ZIndexContext } from '@local/web-design-system/dist/hooks/useZIndex';
import VolumeElementIcon from '@local/web-design-system/dist/icons/Geometry/VolumeElementIcon';
import SettingsIcon from '@local/web-design-system/dist/icons/Menu/SettingsIcon';
import MeasureIcon from '@local/web-design-system/dist/icons/SliceTools/MeasureIcon';
import SliceIcon from '@local/web-design-system/dist/icons/SliceTools/SliceIcon';
import { useTrace } from '@local/web-design-system/dist/utils/trace';
import { MEASUREMENT_TITLE } from '@local/webviz/dist/components/Measurement/MeasurementDialog.constants';
import { Orientation } from '@local/webviz/dist/components/Orientation';
import { Scalebar } from '@local/webviz/dist/components/Scalebar';
import { SETTINGS_TITLE } from '@local/webviz/dist/components/Settings';
import { SLICE } from '@local/webviz/dist/components/Slice/SliceDialog/DockableSliceDialog.constants';
import Tool from '@local/webviz/dist/components/Tool/Tool';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useCallback, useRef, useState } from 'react';

import { AGGREGATE_GEOMETRY_NAME } from 'src/constants';
import { useAppSelector } from 'src/store/store';
import {
    orientationVisible,
    scalebarVisible,
    sceneObjectByName,
} from 'src/store/visualization/selectors';

import {
    DockableSliceDialog,
    DockableDefectsDialog,
    DockableMeasureDialog,
    DockableSettingsDialog,
    DockableAggregateDialog,
} from '../DockableDialogs/DockableDialogs';
import { DefectsIcon } from './icons/DefectsIcon';
import { useStyles } from './SideToolbar.styles';

function getDefaultPositions() {
    const baseX = 245;
    const baseY = 275;
    const yOffset = 110;

    return {
        slicer: { x: baseX, y: baseY + yOffset * 2 },
        measure: { x: baseX, y: baseY + yOffset },
        defects: { x: 0, y: 0 },
        settings: { x: baseX, y: baseY + yOffset * 3 },
        aggregate: { x: baseX, y: baseY },
    };
}

function OrientationWithState() {
    const { classes } = useStyles();
    const isOrientationVisible = useAppSelector(orientationVisible);
    return isOrientationVisible ? <Orientation className={classes.orientationArrows} /> : null;
}

function ScalebarWithState() {
    const { classes } = useStyles();
    const isScalebarVisible = useAppSelector(scalebarVisible);
    return isScalebarVisible ? <Scalebar className={classes.scalebar} /> : null;
}

export function SideToolbar() {
    const { classes } = useStyles();
    const zIndexContext = useZIndexContext();
    const applyTrace = useTrace('side-toolbar');

    const aggregateGeometryObject = useAppSelector(sceneObjectByName(AGGREGATE_GEOMETRY_NAME));

    const sideToolbarRef = useRef<HTMLDivElement>(null);

    const [sliceToolSelected, setSliceToolSelected] = useState(false);
    const handleSliceDialogClosed = useCallback(() => setSliceToolSelected(false), []);
    const handleSliceSelect = useCallback(() => {
        setSliceToolSelected(!sliceToolSelected);
    }, [sliceToolSelected]);

    const [defectsToolSelected, setDefectsToolSelected] = useState(false);
    const handleDefectsDialogClosed = useCallback(() => setDefectsToolSelected(false), []);
    const handleDefectsSelect = useCallback(() => {
        setDefectsToolSelected(!defectsToolSelected);
    }, [defectsToolSelected]);

    const [aggregateToolSelected, setAggregateToolSelected] = useState(false);
    const handleAggregateDialogClosed = useCallback(() => setAggregateToolSelected(false), []);
    const handleAggregateSelect = useCallback(() => {
        setAggregateToolSelected(!aggregateToolSelected);
    }, [aggregateToolSelected]);

    const [measureToolSelected, setMeasureToolSelected] = useState(false);
    const handleMeasureClosed = useCallback(() => {
        setMeasureToolSelected(false);
    }, []);
    const handleMeasureSelect = useCallback(() => {
        setMeasureToolSelected(!measureToolSelected);
    }, [measureToolSelected]);

    const [showSettings, setShowSettings] = useState(false);
    const handleSettingsClosed = useCallback(() => {
        setShowSettings(false);
    }, []);
    const handleSettingsSelect = useCallback(() => {
        setShowSettings(!showSettings);
    }, [showSettings]);

    return (
        <>
            <ZIndexContext.Provider value={zIndexContext}>
                {sliceToolSelected && (
                    <DockableSliceDialog
                        onClose={handleSliceDialogClosed}
                        defaultPosition={getDefaultPositions().slicer}
                    />
                )}
                {defectsToolSelected && (
                    <DockableDefectsDialog
                        onClose={handleDefectsDialogClosed}
                        defaultPosition={getDefaultPositions().defects}
                    />
                )}
                {aggregateToolSelected && (
                    <DockableAggregateDialog
                        onClose={handleAggregateDialogClosed}
                        defaultPosition={getDefaultPositions().aggregate}
                    />
                )}
                {measureToolSelected && (
                    <DockableMeasureDialog
                        onClose={handleMeasureClosed}
                        defaultPosition={getDefaultPositions().measure}
                    />
                )}
                {showSettings && (
                    <DockableSettingsDialog
                        onClose={handleSettingsClosed}
                        defaultPosition={getDefaultPositions().settings}
                    />
                )}
            </ZIndexContext.Provider>
            <Grid className={classes.toolbar} ref={sideToolbarRef}>
                <Grid item className={classes.tools}>
                    <Tool
                        icon={<DefectsIcon {...applyTrace('defects')} />}
                        onClick={handleDefectsSelect}
                        selected={defectsToolSelected}
                        label="Defects"
                        wideIcon
                    />
                    {aggregateGeometryObject && (
                        <Tool
                            icon={<VolumeElementIcon {...applyTrace('aggregate')} />}
                            onClick={handleAggregateSelect}
                            selected={aggregateToolSelected}
                            label="Modeling"
                            wideIcon
                        />
                    )}
                    <Box className={classes.spacer} />
                    <Tool
                        icon={<SliceIcon {...applyTrace('slice')} />}
                        onClick={handleSliceSelect}
                        selected={sliceToolSelected}
                        label={SLICE}
                        wideIcon
                    />
                    <Tool
                        selected={measureToolSelected}
                        icon={<MeasureIcon {...applyTrace('measurement')} />}
                        onClick={handleMeasureSelect}
                        label={MEASUREMENT_TITLE}
                        wideIcon
                    />
                    <Tool
                        selected={showSettings}
                        icon={<SettingsIcon {...applyTrace('settings')} />}
                        onClick={handleSettingsSelect}
                        label={SETTINGS_TITLE}
                        wideIcon
                    />
                </Grid>
            </Grid>
            <OrientationWithState />
            <ScalebarWithState />
        </>
    );
}
