import {
    formGtmMeshTransformationBody,
    useLazyGtmMeshTransformationQuery,
} from 'src/apiClients/gtmCompute/gtmComputeApi';
import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { GtmEvoOutputObject } from 'src/gtmProject/Project.types';

import { useGooseContext } from '../useGooseContext';

function asObjectWithVersionArray(inputMeshes: GtmEvoOutputObject[]) {
    return inputMeshes.map(({ id, version_id }) => ({
        id,
        version: version_id,
    }));
}

export function useLazyAddToAggregate(
    aggregateGeometry: GtmEvoOutputObject,
    inputMeshes: GtmEvoOutputObject[],
) {
    const [GtmMeshTransformationTrigger] = useLazyGtmMeshTransformationQuery();
    const gooseContext = useGooseContext();

    async function AddToAggregateTrigger(tolerance: number, noSelfIntersectionsInParts: boolean) {
        const { data: addResult, isError: addIsError } = await GtmMeshTransformationTrigger(
            formGtmMeshTransformationBody(
                gooseContext!,
                GtmMeshTransformationAction.AddToAggregateGeom,
                asObjectWithVersionArray(inputMeshes),
                {
                    aggregateGeomId: {
                        id: aggregateGeometry.id,
                        version: aggregateGeometry.version_id,
                    },
                    tolerance,
                    noSelfIntersectionsInParts,
                },
            ),
        );
        return { addResult, addIsError };
    }

    return [AddToAggregateTrigger];
}
