import { makeStyles } from 'tss-react/mui';

const styles = () =>
    ({
        menuItem: {
            fontSize: '13px',
        },
    }) as const;

export const useStyles = makeStyles()(styles);
