import ViewInArIcon from '@mui/icons-material/ViewInAr';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import classnames from 'classnames';
import cloneDeep from 'lodash-es/cloneDeep';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { waitForMs } from 'src/apiClients/file/utils';
import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { DEFAULT_TOLERANCE } from 'src/constants';
import { GtmEvoOutputObject, GtmProject } from 'src/gtmProject/Project.types';
import { useLazyAddToAggregate } from 'src/hooks/aggregation/useLazyAddToAggregate';
import { useVolumesManager } from 'src/hooks/modelling/useVolumesManager';
import { useProjectManager } from 'src/hooks/project/useProjectManager';
import { useSceneObjectDataManager } from 'src/hooks/useSceneObjectDataManager';
import { setShowVolumes, setIsAggregated } from 'src/store/project/projectSlice';
import {
    selectCurrentAnalyticalModelIndex,
    selectCurrentProjectData,
    selectCurrentProjectVersionId,
    selectCurrentProjectVolumes,
} from 'src/store/project/selectors';
import { useAppSelector, useAppDispatch } from 'src/store/store';
import { makeHistoryEntry } from 'src/utils/history/history';
import { summarizeTransformationActionHistoryOperation } from 'src/utils/history/historySummary';
import { TransformationProgressModal } from 'src/visualization/TransformationProgressModal/TransformationProgressModal';
import {
    START_UPLOAD_MESSAGE,
    UPLOAD_SUCCESS_MESSAGE,
} from 'src/visualization/TransformationProgressModal/TransformationProgressModal.constants';

import {
    START_AGGREGATE_GEOM_MESSAGE,
    AGGREGATE_GEOM_SUCCESS_MESSAGE,
} from './AggregateControl.constants';
import { useStyles } from './ObjectListItemControl.styles';

interface AggregateControlProps {
    inputMesh: GtmEvoOutputObject;
    aggregateGeometry: GtmEvoOutputObject;
    handleRemoveObject: () => void;
}

export function AggregateControl({
    inputMesh,
    aggregateGeometry,
    handleRemoveObject,
}: AggregateControlProps) {
    const { classes } = useStyles();

    const currentProjectData = useAppSelector(selectCurrentProjectData);
    const currentAnalyticalModelIndex = useAppSelector(selectCurrentAnalyticalModelIndex);
    const currentProjectVersionId = useAppSelector(selectCurrentProjectVersionId);
    const currentProjectVolumes = useAppSelector(selectCurrentProjectVolumes);
    const { projectName } = useParams();
    const { loadGtmObject, removeGtmObject } = useSceneObjectDataManager();
    const { uploadProject } = useProjectManager();
    const [AddToAggregateTrigger] = useLazyAddToAggregate(aggregateGeometry, [inputMesh]);
    const { removeVolumes } = useVolumesManager();
    const dispatch = useAppDispatch();

    const [modalMessage, setModalMessage] = useState<string>('');

    const tolerance = DEFAULT_TOLERANCE;
    const noSelfIntersectionsInParts = true;

    async function performAggregation() {
        // This sequence of operations when performing a transformation is common to all transformations.
        // The other existing transformations (FillHoles and RemoveDegenerateTriangles) essentially have this
        // same code. However, there is a bug with them, tracked through GEOM-446. Refactoring will be done
        // alongside that task. EH will fix this code to be more DRY when he does that task.

        setModalMessage(START_AGGREGATE_GEOM_MESSAGE);
        const { addResult, addIsError } = await AddToAggregateTrigger(
            tolerance,
            noSelfIntersectionsInParts,
        );

        if (addIsError || !addResult || addResult.modified.length === 0) {
            setModalMessage('');
            return;
        }

        const updatedAggregate = addResult!.modified[0];

        await loadGtmObject(updatedAggregate.id, updatedAggregate.version);

        // Adding a surface to the aggregate invalidates the volumes that were computed from the
        // previous aggregate.
        removeVolumes(currentAnalyticalModelIndex);
        currentProjectVolumes.forEach((volume) => removeGtmObject(volume.id));
        dispatch(setShowVolumes([currentAnalyticalModelIndex, false]));

        setModalMessage(`${AGGREGATE_GEOM_SUCCESS_MESSAGE} ${START_UPLOAD_MESSAGE}`);

        const updatedProject: GtmProject = cloneDeep(currentProjectData);
        updatedProject.analytical_models[currentAnalyticalModelIndex].composite_model!.version_id =
            updatedAggregate.version;
        updatedProject.history = updatedProject.history.concat(
            makeHistoryEntry(
                summarizeTransformationActionHistoryOperation(
                    GtmMeshTransformationAction.AddToAggregateGeom,
                    {
                        aggregateGeomId: {
                            id: aggregateGeometry.id,
                            version: aggregateGeometry.version_id,
                        },
                        tolerance,
                        noSelfIntersectionsInParts,
                    },
                ),
                currentProjectVersionId,
            ),
        );
        updatedProject.analytical_models[currentAnalyticalModelIndex].volumes = [];
        updatedProject.analytical_models[currentAnalyticalModelIndex].show_volumes = false;

        const objectIndex = updatedProject.analytical_models[
            currentAnalyticalModelIndex
        ].objects.findIndex((object) => object.id === inputMesh.id);

        if (objectIndex === -1) {
            throw new Error('Object not found in project.');
        }

        updatedProject.analytical_models[currentAnalyticalModelIndex].objects[
            objectIndex
        ].is_aggregated = true;
        dispatch(setIsAggregated([currentAnalyticalModelIndex, objectIndex, true]));
        await uploadProject(updatedProject, projectName!);
        handleRemoveObject();

        setModalMessage(UPLOAD_SUCCESS_MESSAGE);
        await waitForMs(1000);
        setModalMessage('');
    }

    return (
        <>
            <Tooltip title="Add to Aggregate" placement="top" arrow enterDelay={0}>
                <IconButton
                    disabled={aggregateGeometry === undefined}
                    onClick={performAggregation}
                    className={classnames(classes.removeIconButton)}
                >
                    <ViewInArIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
            <TransformationProgressModal open={modalMessage !== ''} dialogContent={modalMessage} />
        </>
    );
}
