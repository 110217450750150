import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import classnames from 'classnames';
import cloneDeep from 'lodash-es/cloneDeep';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { waitForMs } from 'src/apiClients/file/utils';
import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { GtmEvoOutputObject, GtmProject } from 'src/gtmProject/Project.types';
import { useLazyInitAggregate } from 'src/hooks/aggregation/useLazyInitAggregate';
import { useVolumesManager } from 'src/hooks/modelling/useVolumesManager';
import { useProjectManager } from 'src/hooks/project/useProjectManager';
import { useSceneObjectDataManager } from 'src/hooks/useSceneObjectDataManager';
import { setShowVolumes, setIsAggregated } from 'src/store/project/projectSlice';
import {
    selectCurrentAnalyticalModelIndex,
    selectCurrentProjectData,
    selectCurrentProjectVersionId,
    selectCurrentProjectVolumes,
    selectCurrentProjectAnalyticalModelObjects,
} from 'src/store/project/selectors';
import { useAppSelector, useAppDispatch } from 'src/store/store';
import { makeHistoryEntry } from 'src/utils/history/history';
import { summarizeTransformationActionHistoryOperation } from 'src/utils/history/historySummary';
import { TransformationProgressModal } from 'src/visualization/TransformationProgressModal/TransformationProgressModal';
import {
    START_UPLOAD_MESSAGE,
    UPLOAD_SUCCESS_MESSAGE,
} from 'src/visualization/TransformationProgressModal/TransformationProgressModal.constants';

import { useStyles } from './ObjectListItemControl.styles';
import {
    START_RESET_AGGREGATE_GEOM_MESSAGE,
    RESET_AGGREGATE_GEOM_SUCCESS_MESSAGE,
} from './ResetAggregateControl.constants';

interface ResetAggregateControlProps {
    aggregateGeometry: GtmEvoOutputObject | undefined;
}

export function ResetAggregateControl({ aggregateGeometry }: Readonly<ResetAggregateControlProps>) {
    const { classes } = useStyles();

    const currentProjectData = useAppSelector(selectCurrentProjectData);
    const currentAnalyticalModelIndex = useAppSelector(selectCurrentAnalyticalModelIndex);
    const currentProjectVersionId = useAppSelector(selectCurrentProjectVersionId);
    const currentProjectVolumes = useAppSelector(selectCurrentProjectVolumes);
    const currentAnalyticalModelObjects = useAppSelector(
        selectCurrentProjectAnalyticalModelObjects,
    );
    const { projectName } = useParams();
    const { loadGtmObject, removeGtmObject } = useSceneObjectDataManager();
    const { uploadProject } = useProjectManager();
    const [ResetAggregateTrigger] = useLazyInitAggregate();
    const { removeVolumes } = useVolumesManager();
    const dispatch = useAppDispatch();

    const [modalMessage, setModalMessage] = useState<string>('');

    async function performReset() {
        // This sequence of operations when performing a transformation is common to all transformations.
        // The other existing transformations (FillHoles and RemoveDegenerateTriangles) essentially have this
        // same code. However, there is a bug with them, tracked through GEOM-446. Refactoring will be done
        // alongside that task. EH will fix this code to be more DRY when he does that task.

        setModalMessage(START_RESET_AGGREGATE_GEOM_MESSAGE);
        const { initResult, initIsError } = await ResetAggregateTrigger(
            currentProjectData.analytical_models[currentAnalyticalModelIndex].bounds,
        );

        if (initIsError || !initResult || initResult.created.length === 0) {
            setModalMessage('');
            return;
        }

        const updatedAggregate = initResult.created[0];

        await loadGtmObject(updatedAggregate.id, updatedAggregate.version);

        // Resetting the aggregate invalidates the volumes that were computed from the
        // previous aggregate.
        removeVolumes(currentAnalyticalModelIndex);
        currentProjectVolumes.forEach((volume) => removeGtmObject(volume.id));
        dispatch(setShowVolumes([currentAnalyticalModelIndex, false]));

        setModalMessage(`${RESET_AGGREGATE_GEOM_SUCCESS_MESSAGE} ${START_UPLOAD_MESSAGE}`);

        const updatedProject: GtmProject = cloneDeep(currentProjectData);
        updatedProject.analytical_models[currentAnalyticalModelIndex].composite_model!.version_id =
            updatedAggregate.version;
        updatedProject.history = updatedProject.history.concat(
            makeHistoryEntry(
                summarizeTransformationActionHistoryOperation(
                    GtmMeshTransformationAction.InitAggregateGeom,
                    {
                        ...updatedProject.analytical_models[currentAnalyticalModelIndex].bounds,
                    },
                ),
                currentProjectVersionId,
            ),
        );
        updatedProject.analytical_models[currentAnalyticalModelIndex].volumes = [];
        updatedProject.analytical_models[currentAnalyticalModelIndex].show_volumes = false;
        const { objects } = updatedProject.analytical_models[currentAnalyticalModelIndex];
        updatedProject.analytical_models[currentAnalyticalModelIndex].objects = objects.map(
            (object) => ({ ...object, is_aggregated: false }),
        );
        updatedProject.analytical_models[currentAnalyticalModelIndex].objects.forEach(
            (_, objectIndex) => {
                dispatch(setIsAggregated([currentAnalyticalModelIndex, objectIndex, false]));
            },
        );

        await uploadProject(updatedProject, projectName!);

        setModalMessage(UPLOAD_SUCCESS_MESSAGE);
        await waitForMs(1000);
        setModalMessage('');

        currentAnalyticalModelObjects.forEach((object) => {
            loadGtmObject(object.id, object.version_id);
        });
    }

    return (
        <>
            <Tooltip title="Reset Aggregate" placement="top" arrow enterDelay={0}>
                <IconButton
                    disabled={aggregateGeometry === undefined}
                    onClick={performReset}
                    className={classnames(classes.removeIconButton)}
                >
                    <BrokenImageIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
            <TransformationProgressModal open={modalMessage !== ''} dialogContent={modalMessage} />
        </>
    );
}
