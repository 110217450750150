import { Accordion } from '@local/web-design-system/dist/components/Accordion';
import { useState, useCallback, memo, useEffect, useRef, ReactNode } from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

export interface ArtifactAccordionItemProps {
    title?: string;
    icon?: JSX.Element;
    items?: ArtifactAccordionItemProps[];
    id?: string;
    LeafElementType?: any;
    leafElementProps?: any;
}

const ListRow = ({
    items,
    itemIndex,
    setSize,
}: {
    items: ArtifactAccordionItemProps[];
    itemIndex: number;
    setSize: (index: number, size: number) => void;
}) => {
    const rowRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (rowRef.current) {
            setSize(itemIndex, rowRef.current.getBoundingClientRect().height);
        }
    }, [setSize, itemIndex]);

    return (
        <div ref={rowRef}>
            {items[itemIndex].LeafElementType({ ...items[itemIndex].leafElementProps })}
        </div>
    );
};

const AccordionWrapper = ({
    title,
    icon,
    children,
}: {
    children: ReactNode;
    title: string;
    icon: JSX.Element | undefined;
}) => {
    const [expanded, setExpanded] = useState(false);
    const onChange = useCallback(() => setExpanded(!expanded), [expanded]);
    return (
        <Accordion title={title} icon={icon} expanded={expanded} onChange={onChange}>
            {children}
        </Accordion>
    );
};

export const ArtifactVirtualizedList = ({ items }: { items: ArtifactAccordionItemProps[] }) => {
    const listRef = useRef<VariableSizeList | null>(null);
    const sizeMap = useRef<{ [index: number]: number }>({});
    const setSize = useCallback((index: number, size: number) => {
        sizeMap.current = { ...sizeMap.current, [index]: size };
        if (listRef.current) {
            listRef.current.resetAfterIndex(index);
        }
    }, []);
    const getSize = (index: number) => sizeMap.current[index] || 50;

    return (
        <VariableSizeList
            ref={listRef}
            height={200}
            itemCount={items.length}
            itemSize={getSize}
            width="100%"
            itemData={items}
        >
            {({ data, index, style }: ListChildComponentProps) => (
                <div style={style}>
                    <ListRow items={data} itemIndex={index} setSize={setSize} />
                </div>
            )}
        </VariableSizeList>
    );
};

export const ArtifactAccordion = ({
    rootElementTitle,
    rootElementIcon,
    rootElementItems,
}: {
    rootElementTitle: string;
    rootElementIcon?: JSX.Element;
    rootElementItems: ArtifactAccordionItemProps[];
}) => {
    const RecursivelyBuildAccordion = memo(
        ({ title, icon, items = [] }: ArtifactAccordionItemProps) => {
            const hasSubItems =
                items.length > 0 && items.some((item) => item.items && item.items.length > 0);

            if (hasSubItems) {
                return (
                    <AccordionWrapper title={title || ''} icon={icon}>
                        {items.map((item: any) => (
                            <RecursivelyBuildAccordion {...item} key={item.id} />
                        ))}
                    </AccordionWrapper>
                );
            }

            return (
                <AccordionWrapper title={title || ''} icon={icon}>
                    <ArtifactVirtualizedList items={items} />
                </AccordionWrapper>
            );
        },
    );

    return (
        <RecursivelyBuildAccordion
            title={rootElementTitle}
            icon={rootElementIcon}
            items={rootElementItems}
        />
    );
};
