import { getConfig } from '@local/app-config';
import { ContentArea } from '@local/content-area';
import { useGetUserDetailsQuery } from '@local/login';
import { getCurrentUserDetails } from '@local/login/dist/store/sessionStorageHelpers/entitlementsHelper/entitlementsHelper';
import { Messages } from '@local/messages/dist/Messages';
import { NavDrawerItem } from '@local/web-design-system/dist/components/NavDrawer';
import { ColorVariant } from '@local/web-design-system/dist/components/types';
import { darkTheme, theme } from '@local/web-design-system/dist/styles/theme';
import { useTrace } from '@local/web-design-system/dist/utils';
import { ThemeProvider } from '@mui/material/styles';
import classnames from 'classnames';
import { useContext } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { HubSelector } from 'src/components/contentScaffolding/HubSelector';
import { TAGLINE, VERSION_LABEL } from 'src/constants';
import { WDS2ThemeContext } from 'src/context/ThemeContext/ThemeContext';

import logo from '../../assets/logo.svg';
import { LogoTitle } from '../../assets/logoTitle';
import { GtmBreadcrumbs } from '../GtmBreadcrumbs';
import { useStyles } from './ContentScaffolding.styles';

export const ContentScaffolding = () => {
    const { classes } = useStyles();
    const location = useLocation();
    const applyTrace = useTrace('gtm-content-div');
    const themeContext = useContext(WDS2ThemeContext);

    const user = (() => {
        if (getConfig().bentleyIdEnabled) {
            return getCurrentUserDetails();
        }
        return useGetUserDetailsQuery();
    })();

    const navItems: NavDrawerItem[] = [
        {
            key: 'modeler',
            text: 'Modeler',
            location: '/',
            icon: 'evo',
        },
    ];
    const breadcrumbComponent = (
        <>
            <GtmBreadcrumbs />
            <HubSelector />
        </>
    );

    const footerComponent = (
        <div automation-id="gtm-version" className={classes.footer} data-dd-privacy="allow">
            {TAGLINE}&nbsp;&nbsp;&nbsp;{VERSION_LABEL}
        </div>
    );

    return (
        <ThemeProvider theme={themeContext.theme === 'dark' ? darkTheme : theme}>
            <ContentArea
                currentRoute={location.pathname}
                navItems={navItems}
                service="geotechnical-modeler"
                variant={themeContext.theme as ColorVariant}
                footerComponent={footerComponent}
                userResult={user}
                exactMatch={false}
                logoOnly={<img src={logo} className={classes.appLogo} alt="Modeler" />}
                logoWithTitle={<LogoTitle />}
                evoSelector={breadcrumbComponent}
            >
                <div
                    className={classnames(classes.appContent, classes.webVizContent)}
                    {...applyTrace()}
                >
                    <Outlet />
                </div>
                <Messages />
            </ContentArea>
        </ThemeProvider>
    );
};
