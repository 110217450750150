import { CameraTools } from '@local/webviz/dist/components/CameraTools';
import Grid from '@mui/material/Grid';

import { useStyles } from './Toolbar.styles';

export function Toolbar() {
    const { classes } = useStyles();

    return (
        <Grid container item className={classes.root} justifyContent="center">
            <Grid container item className={classes.tools} alignItems="center">
                <CameraTools labelsOn />
            </Grid>
        </Grid>
    );
}
