/* eslint-disable no-param-reassign */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { GtmEvoOutputObject } from 'src/gtmProject';

import {
    BaseArtifacts,
    CurrentProjectState,
    DEFAULT_BASE_ARTIFACTS_STATE,
    DEFAULT_CURRENT_PROJECT_STATE,
    DefectData,
    DefectObjectData,
    ProjectState,
} from './projectSlice.types';
// eslint-disable-next-line import/no-cycle
import { initialProjectState } from './selectors';

export const projectSlice = createSlice({
    name: 'project',
    initialState: initialProjectState,
    reducers: {
        addOrUpdateDefectsByObjectId(
            projectState: ProjectState,
            { payload: [objectId, objectData] }: PayloadAction<[string, DefectData]>,
        ) {
            const initialData = projectState.defectsObjectMap[objectId];
            projectState.defectsObjectMap[objectId] = {
                ...initialData,
                defects: {
                    ...initialData?.defects,
                    [objectData.action]: objectData.data,
                },
            };
        },
        increaseDefectTotalByObjectId(
            projectState: ProjectState,
            { payload: [objectId, count] }: PayloadAction<[string, number]>,
        ) {
            const initialData = projectState.defectsObjectMap[objectId];
            projectState.defectsObjectMap[objectId] = {
                ...initialData,
                total: (initialData.total ?? 0) + count,
            };
        },
        addOrUpdateDefectObjectById(
            projectState: ProjectState,
            { payload: [objectId, objectData] }: PayloadAction<[string, Partial<DefectObjectData>]>,
        ) {
            const initialData = projectState.defectsObjectMap[objectId];
            projectState.defectsObjectMap[objectId] = { ...initialData, ...objectData };
        },
        removeDefectsByObjectId(projectState: ProjectState, { payload }: PayloadAction<string>) {
            delete projectState.defectsObjectMap[payload];
        },
        clearDefects(projectState: ProjectState) {
            projectState.defectsObjectMap = {};
        },
        setCurrentProject(
            projectState: ProjectState,
            { payload }: PayloadAction<Partial<CurrentProjectState>>,
        ) {
            return {
                ...projectState,
                current: {
                    ...projectState.current,
                    project: { ...projectState.current.project, ...payload.project },
                },
            };
        },
        clearCurrentProject(projectState: ProjectState) {
            projectState.current = DEFAULT_CURRENT_PROJECT_STATE;
        },
        setBaseArtifacts(
            projectState: ProjectState,
            { payload }: PayloadAction<Partial<BaseArtifacts>>,
        ) {
            return {
                ...projectState,
                base: {
                    ...projectState.base,
                    objects: {
                        ...projectState.base.objects,
                        ...payload.objects,
                    },
                },
            };
        },
        clearBaseArtifacts(projectState: ProjectState) {
            projectState.base = DEFAULT_BASE_ARTIFACTS_STATE;
        },
        setCurrentSelectedAnalyticalModelIndex(
            projectState: ProjectState,
            { payload }: PayloadAction<number>,
        ) {
            projectState.current.selectedAnalyticalModelIndex = payload;
        },
        setCurrentProjectVersionId(projectState: ProjectState, { payload }: PayloadAction<string>) {
            projectState.currentProjectVersionId = payload;
        },
        setVolumes(
            projectState: ProjectState,
            {
                payload: [analyticalModelIndex, volumes],
            }: PayloadAction<[number, GtmEvoOutputObject[]]>,
        ) {
            projectState.current.project.analytical_models[analyticalModelIndex].volumes = volumes;
        },
        clearVolumes(
            projectState: ProjectState,
            { payload: analyticalModelIndex }: PayloadAction<number>,
        ) {
            projectState.current.project.analytical_models[analyticalModelIndex].volumes = [];
        },
        setShowVolumes(
            projectState: ProjectState,
            { payload: [analyticalModelIndex, showVolumes] }: PayloadAction<[number, boolean]>,
        ) {
            projectState.current.project.analytical_models[analyticalModelIndex].show_volumes =
                showVolumes;
        },
        setIsAggregated(
            projectState: ProjectState,
            {
                payload: [analyticalModelIndex, objectIndex, isAggregated],
            }: PayloadAction<[number, number, boolean]>,
        ) {
            projectState.current.project.analytical_models[analyticalModelIndex].objects[
                objectIndex
            ].is_aggregated = isAggregated;
        },
    },
});

export const {
    addOrUpdateDefectsByObjectId,
    addOrUpdateDefectObjectById,
    removeDefectsByObjectId,
    clearDefects,
    increaseDefectTotalByObjectId,
    setCurrentProject,
    clearCurrentProject,
    setBaseArtifacts,
    clearBaseArtifacts,
    setCurrentProjectVersionId,
    setVolumes,
    clearVolumes,
    setShowVolumes,
    setIsAggregated,
} = projectSlice.actions;
