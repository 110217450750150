import { getCombinedToken as getAccessToken } from '@local/login/dist/store/sessionStorageHelpers/accessTokenHelper/accessTokenHelper';
import { getCurrentEvoInstance } from '@local/login/dist/store/sessionStorageHelpers/entitlementsHelper/entitlementsHelper';
import { useParams } from 'react-router-dom';

import {
    formGtmMeshDetectorBody,
    useLazyGtmMeshDetectorQuery,
} from 'src/apiClients/gtmCompute/gtmComputeApi';
import {
    GtmGooseContext,
    GtmMeshDetectionData,
    GtmMeshDetectorAction,
    GtmMeshDetectorParams,
    GtmObjectType,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { DEFAULT_TOLERANCE } from 'src/constants';
import { GtmEvoOutputObject } from 'src/gtmProject/Project.types';
import { geoscienceObjectByVersion } from 'src/hooks/utils';
import {
    addOrUpdateDefectObjectById,
    addOrUpdateDefectsByObjectId,
    increaseDefectTotalByObjectId,
} from 'src/store/project/projectSlice';
import { selectDefectsMap } from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { getTransformationParam, TransformationParam } from 'src/utils/tranformationParamStorage';

export const DEFAULT_DETECTOR_PARAMS = {
    // Default needle threshold loosely linked to cap definition: for an isoceles cap triangle at the threshold we have
    // that length-of-base / cap-height = 89.5, hence rounded up we set the ratio to 100.
    needleThresholdRatio: 100,
    capMinAngleDegrees: 177.5,
    needleCollapseLength: 0,
    holeSizeRatioTolerance: 0.5,
    tolerance: DEFAULT_TOLERANCE,
};

export function getDefectsCount(
    action: GtmMeshDetectorAction,
    defects: GtmMeshDetectionData | undefined,
) {
    if (!defects) {
        return 0;
    }
    // Each fin and hole is a collection of entities that we count as one defect - i.e., a "defect group".
    if (
        action === GtmMeshDetectorAction.DetectFins ||
        action === GtmMeshDetectorAction.DetectHoles ||
        action === GtmMeshDetectorAction.DetectNonPartitioningSurfaces
    ) {
        return defects.length;
    }

    if (defects.length === 0) {
        return 0;
    }

    return (
        (defects[0].points?.length ?? 0) +
        (defects[0].edges?.length ?? 0) +
        (defects[0].triangles?.length ?? 0)
    );
}

export function useDefectsLoadingManager() {
    const [GtmDetectorTrigger] = useLazyGtmMeshDetectorQuery();
    const { orgUuid, workspaceUuid } = useParams();
    const { hub } = getCurrentEvoInstance();
    const dispatch = useAppDispatch();
    const defectsMap = useAppSelector(selectDefectsMap);

    // Remove the protocol from the url since the backend doesn't expect it
    const hostUrl = hub?.url?.replace(/(^\w+:|^)\/\//, '');
    const accessToken = getAccessToken()?.access_token;

    const getGtmObjectType = (artifactObject: GtmEvoOutputObject): GtmObjectType => {
        // This is a fairly fragile way to determine the type. Probably should be changed sometime to be explicitly tracked on the SceneObject.
        if (!artifactObject.name) {
            return GtmObjectType.Undefined;
        }
        if (artifactObject.name === 'Aggregate Geometry') {
            return GtmObjectType.AggregateGeometry;
        }
        if (artifactObject.name.startsWith('Volume')) {
            return GtmObjectType.VolumeShell;
        }

        return GtmObjectType.Undefined;
    };

    const detectorIsApplicable = (action: GtmMeshDetectorAction, gtmObject: GtmEvoOutputObject) => {
        // By default all detectors all always applicable. For now we explicitly limit per detector.
        const notApplicableMapping = new Map<GtmObjectType, GtmMeshDetectorAction[]>([
            [
                // All intersection of three surfaces have non-manifold edges and vertices, we don't
                // want to show these defects in the aggregate geometry.
                GtmObjectType.AggregateGeometry,
                [
                    GtmMeshDetectorAction.DetectNonManifoldEdges,
                    GtmMeshDetectorAction.DetectNonManifoldVertices,
                ],
            ],
            [GtmObjectType.VolumeShell, [GtmMeshDetectorAction.DetectNonPartitioningSurfaces]],
            [GtmObjectType.Undefined, [GtmMeshDetectorAction.DetectNonPartitioningSurfaces]],
        ]);

        const objectType = getGtmObjectType(gtmObject);
        const notApplicableActions = notApplicableMapping.get(objectType) ?? [];
        if (notApplicableActions.includes(action)) {
            return false;
        }

        return true;
    };

    async function runDetector(
        artifactObject: GtmEvoOutputObject,
        action: GtmMeshDetectorAction,
        params: GtmMeshDetectorParams = {},
    ) {
        if (!accessToken || !hostUrl || !orgUuid || !workspaceUuid) return;

        const context: GtmGooseContext = {
            host: hostUrl,
            apiToken: getAccessToken()?.access_token || '',
            orgId: orgUuid,
            workspaceId: workspaceUuid,
        };

        try {
            if (
                artifactObject.id &&
                'version_id' in artifactObject &&
                detectorIsApplicable(action, artifactObject)
            ) {
                const { data: defects } = await GtmDetectorTrigger(
                    formGtmMeshDetectorBody(
                        context,
                        action,
                        geoscienceObjectByVersion(artifactObject.id, artifactObject.version_id),
                        params,
                    ),
                );

                const defectCount = getDefectsCount(action, defects);

                if (defects && defectCount > 0) {
                    dispatch(
                        addOrUpdateDefectsByObjectId([
                            artifactObject.id,
                            { action, data: { defects, count: defectCount } },
                        ]),
                    );

                    dispatch(
                        increaseDefectTotalByObjectId([
                            artifactObject.id,
                            Math.ceil(defectCount / 2),
                        ]),
                    );
                }
            }
        } catch (error) {
            console.error(`Error running detector : ${action}`, error);
            dispatch(
                addOrUpdateDefectObjectById([
                    artifactObject.id,
                    { isLoading: false, isError: true },
                ]),
            );
        }
    }

    async function runAllDetectors(artifactObject: GtmEvoOutputObject) {
        if (defectsMap[artifactObject.id]?.versionId === artifactObject.version_id) return;

        dispatch(
            addOrUpdateDefectObjectById([
                artifactObject.id,
                { versionId: artifactObject.version_id, isLoading: true },
            ]),
        );

        const actions = [
            {
                action: GtmMeshDetectorAction.DetectDegenerateTris,
                params: {
                    needleThresholdRatio:
                        getTransformationParam(TransformationParam.needleThresholdRatio) ??
                        DEFAULT_DETECTOR_PARAMS.needleThresholdRatio,
                    capMinAngleDegrees:
                        getTransformationParam(TransformationParam.capMinimumAngleInDegrees) ??
                        DEFAULT_DETECTOR_PARAMS.capMinAngleDegrees,
                    needleCollapseLength:
                        getTransformationParam(TransformationParam.needleCollapseLength) ??
                        DEFAULT_DETECTOR_PARAMS.needleCollapseLength,
                },
            },
            { action: GtmMeshDetectorAction.DetectDuplicatePoints },
            { action: GtmMeshDetectorAction.DetectDuplicateTris },
            { action: GtmMeshDetectorAction.DetectNonPartitioningSurfaces },
            {
                action: GtmMeshDetectorAction.DetectHoles,
                params: { holeSizeRatioTolerance: 0.5 },
            },
            {
                action: GtmMeshDetectorAction.DetectSelfIntersections,
                params: { tolerance: DEFAULT_TOLERANCE },
            },
            { action: GtmMeshDetectorAction.DetectNonManifoldEdges },
            { action: GtmMeshDetectorAction.DetectNonManifoldVertices },
            { action: GtmMeshDetectorAction.DetectInconsistentlyOrientedTris },
            // Add other detectors here ...
        ];

        const promises = actions.map(({ action, params }) =>
            runDetector(artifactObject, action, params),
        );
        await Promise.all(promises);

        dispatch(addOrUpdateDefectObjectById([artifactObject.id, { isLoading: false }]));
    }
    return { runAllDetectors };
}
