import { EdgesToggleIcon } from '@local/web-design-system/dist/icons/PropertiesPanel/EdgesToggleIcon';
import { FacesToggleIcon } from '@local/web-design-system/dist/icons/PropertiesPanel/FacesToggleIcon';
import { DEFAULT_TWEEN } from '@local/webviz/dist/context/constants';
import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import { createSnapshotToUpdateWireframe } from '@local/webviz/dist/context/snapshots/wireframe';
import IconButton from '@mui/material/IconButton/IconButton';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import classnames from 'classnames';

import { useStyles } from './ObjectListItemControl.styles';
import { PropertiesToggleControlProps } from './PropertiesToggleControl.types';

const SHOW_WIRE_FRAME_TOOLTIP = 'Show Edges';
const REMOVE_WIRE_FRAME_TOOLTIP = 'Remove Edges';

export function WireframeControl({ viewId }: Readonly<PropertiesToggleControlProps>) {
    const { getEntityState, setStateFromSnapshot } = useBaseXyz();
    const { classes } = useStyles();
    const entityState = getEntityState(viewId);
    const isSelectedViewsWireframeActive =
        entityState && 'wireframe' in entityState && entityState.wireframe === true;

    function handleToggle() {
        const snapshot = createSnapshotToUpdateWireframe(!isSelectedViewsWireframeActive, viewId);
        setStateFromSnapshot(snapshot, { tween: DEFAULT_TWEEN });
    }

    return (
        <Tooltip
            title={
                isSelectedViewsWireframeActive ? REMOVE_WIRE_FRAME_TOOLTIP : SHOW_WIRE_FRAME_TOOLTIP
            }
            placement="top"
            arrow
            enterDelay={0}
        >
            <IconButton onClick={handleToggle} className={classnames(classes.removeIconButton)}>
                {isSelectedViewsWireframeActive ? (
                    <FacesToggleIcon fontSize="inherit" />
                ) : (
                    <EdgesToggleIcon fontSize="inherit" />
                )}
            </IconButton>
        </Tooltip>
    );
}
