import ViewCarouselSharp from '@mui/icons-material/ViewCarouselSharp';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import classnames from 'classnames';
import cloneDeep from 'lodash-es/cloneDeep';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { waitForMs } from 'src/apiClients/file/utils';
import {
    formGtmMeshTransformationBody,
    useLazyGtmMeshTransformationQuery,
} from 'src/apiClients/gtmCompute/gtmComputeApi';
import {
    GtmMeshTransformationAction,
    GtmRemeshParams,
    ObjectIdWithVersion,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { GtmEvoOutputObject, GtmProject } from 'src/gtmProject/Project.types';
import { useProjectManager } from 'src/hooks/project/useProjectManager';
import { useGooseContext } from 'src/hooks/useGooseContext';
import { useSceneObjectDataManager } from 'src/hooks/useSceneObjectDataManager';
import {
    selectCurrentAnalyticalModelIndex,
    selectCurrentProjectData,
    selectCurrentProjectVersionId,
} from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { makeHistoryEntry } from 'src/utils/history/history';
import { summarizeTransformationActionHistoryOperation } from 'src/utils/history/historySummary';
import { TransformationProgressModal } from 'src/visualization/TransformationProgressModal/TransformationProgressModal';
import {
    START_UPLOAD_MESSAGE,
    UPLOAD_SUCCESS_MESSAGE,
} from 'src/visualization/TransformationProgressModal/TransformationProgressModal.constants';

import { useStyles } from './ObjectListItemControl.styles';
import { START_REMESH_MESSAGE, REMESH_SUCCESS_MESSAGE } from './RemeshControl.constants';

interface RemeshControlProps {
    inputMesh: GtmEvoOutputObject;
}

export const DEFAULT_REMESH_PARAMS = {
    patchAngleTolerance: 10.0, // Angle change in degrees to define a patch skeleton line
    maxChordalError: -0.005, // max relative local error (-0.005 = 0.5%)
    strainTolerance: 0.3, // relative quality difference before and after unfolding to plane
    isClosed: false, // should be true for manifold GMVs
    initialCleanup: true, // e.g. node merging, gaps filling, topological fixing
    lineProximityDetection: false, // Consider distance between skeleton lines for element sizes
    optimize: true, // perform final optimization
    shapeQualityWeight: 0.6, // size vs shape weight (0 to 1)
    targetH: 0, // target element size (0 is based on skeleton lines)
};

export function RemeshControl({ inputMesh }: RemeshControlProps) {
    const { classes } = useStyles();

    const gooseContext = useGooseContext();
    const currentProjectData = useAppSelector(selectCurrentProjectData);
    const currentAnalyticalModelIndex = useAppSelector(selectCurrentAnalyticalModelIndex);
    const currentProjectVersionId = useAppSelector(selectCurrentProjectVersionId);
    const { projectName } = useParams();
    const { loadGtmObject, removeGtmObject } = useSceneObjectDataManager();
    const { uploadProject } = useProjectManager();
    const [GtmMeshTransformationTrigger] = useLazyGtmMeshTransformationQuery();

    const [modalMessage, setModalMessage] = useState<string>('');

    // TODO: unsure yet how/where these will be set
    const [patchAngleTolerance /* setPatchAngleTolerance */] = useState<number>(
        DEFAULT_REMESH_PARAMS.patchAngleTolerance,
    );
    const [maxChordalError /* setMaxChordalError */] = useState<number>(
        DEFAULT_REMESH_PARAMS.maxChordalError,
    );
    const [strainTolerance /* setStrainTolerance */] = useState<number>(
        DEFAULT_REMESH_PARAMS.strainTolerance,
    );
    const [isClosed /* setIsClosed */] = useState<boolean>(DEFAULT_REMESH_PARAMS.isClosed);
    const [initialCleanup /* setInitialCleanup */] = useState<boolean>(
        DEFAULT_REMESH_PARAMS.initialCleanup,
    );
    const [lineProximityDetection /* setLineProximityDetection */] = useState<boolean>(
        DEFAULT_REMESH_PARAMS.lineProximityDetection,
    );
    const [optimize /* setOptimize */] = useState<boolean>(DEFAULT_REMESH_PARAMS.optimize);
    const [shapeQualityWeight /* setShapeQualityWeight */] = useState<number>(
        DEFAULT_REMESH_PARAMS.shapeQualityWeight,
    );
    const [targetH /* setTargetH */] = useState<number>(DEFAULT_REMESH_PARAMS.targetH);

    const remeshTransformation = useCallback(
        async (object: ObjectIdWithVersion, params: GtmRemeshParams) => {
            const { data: result } = await GtmMeshTransformationTrigger(
                formGtmMeshTransformationBody(
                    gooseContext!,
                    GtmMeshTransformationAction.Remesh,
                    [object],
                    params,
                ),
            );
            return result;
        },
        [gooseContext, GtmMeshTransformationTrigger],
    );

    async function performRemesh() {
        // This sequence of operations when performing a transformation is common to all transformations.
        // The other existing transformations (FillHoles and RemoveDegenerateTriangles) essentially have this
        // same code. However, there is a bug with them, tracked through GEOM-446. Refactoring will be done
        // alongside that task. EH will fix this code to be more DRY when he does that task.

        setModalMessage(START_REMESH_MESSAGE);
        const params: GtmRemeshParams = {
            patchAngleTolerance,
            maxChordalError,
            strainTolerance,
            isClosed,
            initialCleanup,
            lineProximityDetection,
            optimize,
            shapeQualityWeight,
            targetH,
        };

        const result = await remeshTransformation(
            { id: inputMesh.id, version: inputMesh.version_id },
            params,
        );
        if (!result || result.modified.length === 0) {
            setModalMessage('');
            return;
        }

        const updatedObject = result!.modified[0];
        await removeGtmObject(inputMesh.id);
        await loadGtmObject(updatedObject.id, updatedObject.version);
        setModalMessage(`${REMESH_SUCCESS_MESSAGE} ${START_UPLOAD_MESSAGE}`);

        // Update the project data
        const updatedProject: GtmProject = cloneDeep(currentProjectData);
        const surfaceIndex = updatedProject.analytical_models[
            currentAnalyticalModelIndex
        ].objects.findIndex((input: GtmEvoOutputObject) => input.id === inputMesh.id);
        if (surfaceIndex !== -1) {
            // This is an input surface
            updatedProject.analytical_models[currentAnalyticalModelIndex].objects[
                surfaceIndex
            ].version_id = updatedObject.version;
        } else {
            // This is the aggregate geometry
            updatedProject.analytical_models[
                currentAnalyticalModelIndex
            ].composite_model!.version_id = updatedObject.version;
        }
        const newHistoryEntry = makeHistoryEntry(
            summarizeTransformationActionHistoryOperation(
                GtmMeshTransformationAction.Remesh,
                params,
            ),
            currentProjectVersionId,
        );
        updatedProject.history = [newHistoryEntry, ...(updatedProject.history || [])];
        await uploadProject(updatedProject, projectName!);

        setModalMessage(UPLOAD_SUCCESS_MESSAGE);
        await waitForMs(1000);
        setModalMessage('');
    }

    // TODO: this is a temporary icon
    return (
        <>
            <Tooltip title="Remesh" placement="top" arrow enterDelay={0}>
                <IconButton
                    onClick={performRemesh}
                    className={classnames(classes.removeIconButton)}
                >
                    <ViewCarouselSharp fontSize="inherit" />
                </IconButton>
            </Tooltip>
            <TransformationProgressModal open={modalMessage !== ''} dialogContent={modalMessage} />
        </>
    );
}
