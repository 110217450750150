import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const styles = (theme: Theme) =>
    ({
        root: {
            position: 'relative',
        },
        artifactListItemControl: {
            zIndex: 2,
            minHeight: theme.spacing(3),
            backgroundColor: 'transparent',
        },
        mainContainer: {
            height: '100%',
            color: theme.palette.grey[700],
            position: 'relative',
            border: '1px solid transparent',
            '&:focus': {
                outline: 'none',
                border: `1px solid ${theme.palette.grey[300]}`,
            },
        },
        nameContainer: {
            paddingLeft: theme.spacing(0.5),
        },
        icon: {
            fontSize: 12,
            minWidth: 10,
            paddingTop: theme.spacing(0.5),
            cursor: 'pointer',
        },
        name: {
            paddingTop: theme.spacing(0.25),
            paddingLeft: theme.spacing(),
            fontSize: 13,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            alignSelf: 'center',
        },
        errorContainer: {
            backgroundColor: theme.palette.grey[50],
            border: `1px solid ${theme.palette.grey[200]}`,
        },
        actionDivider: {
            height: 'auto',
            margin: theme.spacing(0, 1),
        },
    }) as const;

export const useStyles = makeStyles()(styles);
