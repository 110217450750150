import { getCombinedToken as getAccessToken } from '@local/login/dist/store/sessionStorageHelpers/accessTokenHelper/accessTokenHelper';
import { getCurrentEvoInstance } from '@local/login/dist/store/sessionStorageHelpers/entitlementsHelper/entitlementsHelper';
import { useParams } from 'react-router-dom';

import { GtmGooseContext } from 'src/apiClients/gtmCompute/gtmComputeApi.types';

export function buildGooseContext(): GtmGooseContext | null {
    const accessToken = getAccessToken()?.access_token;
    const { org, hub } = getCurrentEvoInstance();
    const params = useParams();
    const { workspaceUuid } = params;

    if (hub?.url && accessToken && org?.id && workspaceUuid) {
        // Remove the protocol from the url since the backend doesn't expect it
        const hostUrl = hub?.url?.replace(/(^\w+:|^)\/\//, '');
        return {
            host: hostUrl,
            apiToken: accessToken,
            orgId: org?.id,
            workspaceId: workspaceUuid,
        };
    }
    return null;
}

export function geoscienceObjectByVersion(objectId: string, versionId: string) {
    if (objectId && versionId) {
        return [{ id: objectId, version: versionId }];
    }
    return [];
}
