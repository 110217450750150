import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const styles = (theme: Theme) =>
    ({
        dialogContainer: {
            borderRadius: 0,
            width: theme.spacing(70),
            padding: theme.spacing(2),
        },
        dialogContent: {
            padding: theme.spacing(0, 5, 3, 2),
        },
        contentText: {
            fontSize: '13px',
            fontWeight: 400,
            lineHeight: '21px',
            marginBottom: 0,
            paddingTop: theme.spacing(1),
        },
    }) as const;

export const useStyles = makeStyles()(styles);
