import {
    formGtmMeshTransformationBody,
    useLazyGtmMeshTransformationQuery,
} from 'src/apiClients/gtmCompute/gtmComputeApi';
import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { AGGREGATE_GEOMETRY_NAME, MESH_SCHEMA } from 'src/constants';
import { GtmEvoFileType, GtmEvoOutputObject } from 'src/gtmProject/Project.types';
import { useGooseContext } from 'src/hooks/useGooseContext';
import { setVolumes, clearVolumes } from 'src/store/project/projectSlice';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { sceneObjectByName } from 'src/store/visualization/selectors';
import { rgbArrayToGtmColor } from 'src/utils/typeTransformations';
import { initialColorGenerator } from 'src/visualization/context/generateData';

export function useVolumesManager() {
    const [GtmMeshTransformationTrigger] = useLazyGtmMeshTransformationQuery();
    const dispatch = useAppDispatch();
    const gooseContext = useGooseContext();
    const aggregateGeometryObject = useAppSelector(sceneObjectByName(AGGREGATE_GEOMETRY_NAME));

    async function separateVolumes(analyticalModelIndex: number): Promise<GtmEvoOutputObject[]> {
        if (!aggregateGeometryObject) {
            return Promise.reject(
                new Error('No aggregate geometry object to separate volumes from.'),
            );
        }

        const { data: result, isError } = await GtmMeshTransformationTrigger(
            formGtmMeshTransformationBody(
                gooseContext!,
                GtmMeshTransformationAction.SeparateVolumes,
                [
                    {
                        id: aggregateGeometryObject.objectId,
                        version: aggregateGeometryObject.versionId,
                    },
                ],
                {},
            ),
        );

        if (!isError && result?.created?.length) {
            // This is a kludge to get a default color for us devs.
            const initialColor = initialColorGenerator();
            const volumes = result.created.map(({ id, version }, index) => ({
                type: GtmEvoFileType.GeoscienceObject,
                name: `Volume ${index + 1}`,
                id,
                version_id: version,
                schema: MESH_SCHEMA,
                color: rgbArrayToGtmColor(initialColor),
            }));
            dispatch(setVolumes([analyticalModelIndex, volumes]));

            return volumes;
        }

        return Promise.reject(new Error('Error separating volumes from aggregate geometry'));
    }

    async function removeVolumes(analyticalModelIndex: number) {
        dispatch(clearVolumes(analyticalModelIndex));
    }

    return { separateVolumes, removeVolumes };
}
