import { common } from '@local/web-design-system/dist/styles/common';
import { fonts } from '@local/web-design-system/dist/styles/fonts';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const styles = (theme: Theme) =>
    ({
        root: {
            ...common(theme).thinScrollBar,
            padding: theme.spacing(1, 2),
            overflowY: 'auto',
            overflowX: 'hidden',
            height: '500px',
        },
        emptyStateMessageContainer: {
            height: '60%',
        },
        emptyStateMessage: {
            fontSize: '14px',
            fontWeight: fonts.weights.regular,
            color: theme.palette.grey[700],
        },
        defectIcon: {
            fontSize: '14px',
            marginLeft: theme.spacing(1),
            fontWeight: fonts.weights.regular,
            color: theme.palette.warning.main,
            maxWidth: '25%',
        },
        accordionDefectsContainer: {
            paddingTop: theme.spacing(1.2),
            paddingLeft: theme.spacing(1.2),
        },
        defectsCount: {
            paddingLeft: theme.spacing(0.5),
        },
    }) as const;

export const useStyles = makeStyles()(styles);
