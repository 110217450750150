import {
    GtmMeshDetectionData,
    GtmMeshDetectorAction,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { GtmEvoOutputObject, GtmProject } from 'src/gtmProject/Project.types';

export interface GtmMeshDetectionDataWithCount {
    defects: GtmMeshDetectionData;
    count: number;
}

export type DetectionData = {
    [action in GtmMeshDetectorAction]?: GtmMeshDetectionDataWithCount;
};

export interface DefectObjectData {
    objectId: string;
    versionId: string;
    isLoading: boolean;
    isError: boolean;
    defects: DetectionData;
    total: number;
}

export const DEFAULT_DEFECT_OBJECT_DATA: DefectObjectData = {
    objectId: '',
    versionId: '',
    isLoading: false,
    isError: false,
    defects: {},
    total: 0,
};

export const DEFAULT_CURRENT_PROJECT_STATE: CurrentProjectState = {
    project: {} as GtmProject,
    selectedAnalyticalModelIndex: 0,
};

export interface DefectData {
    action: GtmMeshDetectorAction;
    data: GtmMeshDetectionDataWithCount;
}

export type DefectsObjectMap = Record<string, DefectObjectData>; // object_id: DefectObjectData

export interface CurrentProjectState {
    project: GtmProject;
    selectedAnalyticalModelIndex: number;
}

export interface BaseArtifacts {
    objects: GtmEvoOutputObject[];
}

export const DEFAULT_BASE_ARTIFACTS_STATE: BaseArtifacts = {
    objects: [],
};

export interface ProjectState {
    current: CurrentProjectState;
    currentProjectVersionId: string;
    defectsObjectMap: DefectsObjectMap;
    base: BaseArtifacts;
}
