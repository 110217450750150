import { generateEntity } from '@local/webviz/dist/context/snapshots/base';
import { Color, UpdateSnapshot } from '@local/webviz/dist/types/xyz';
import { ElementClass, LinesMode, ViewClass } from '@local/webviz/dist/xyz';

const DEFAULT_DEFECT_COLOR = [255, 0, 0] as Color;
const DEFAULT_DEFECT_OPACITY = 1;
const DEFAULT_HIGHLIGHT_COLOR = [248, 100, 100] as Color;

export function getLinesSnapshot(
    elementId: string,
    viewId: string,
    vertices: Float32Array,
    segments: Int32Array,
    highlight?: boolean,
    color?: Color,
): UpdateSnapshot {
    // Set default color, depending on highlight.
    let actualColor = highlight ? DEFAULT_HIGHLIGHT_COLOR : DEFAULT_DEFECT_COLOR;
    // Allow override by parameter.
    actualColor = color ?? actualColor;
    return {
        [elementId]: {
            id: elementId,
            __class__: ElementClass.Lines,
            vertices,
            segments,
        },

        [viewId]: generateEntity(ViewClass.Lines, {
            id: viewId,
            element: elementId,
            mode: LinesMode.Lines,
            color: actualColor,
            width: highlight ? 8 : 4,
        }),
    };
}

export function getDefectedSurfaceSnapshot(
    elementId: string,
    viewId: string,
    vertices: Float32Array,
    indices: Int32Array,
    highlight?: boolean,
): UpdateSnapshot {
    return {
        [elementId]: {
            id: elementId,
            __class__: ElementClass.Surface,
            vertices,
            triangles: indices,
        },

        [viewId]: generateEntity(ViewClass.Surface, {
            id: viewId,
            element: elementId,
            color: highlight ? DEFAULT_HIGHLIGHT_COLOR : DEFAULT_DEFECT_COLOR,
            wireframe: true,
            showFaces: false,
            opacity: DEFAULT_DEFECT_OPACITY,
        }),
    };
}

export function getPointsSnapshot(
    elementId: string,
    viewId: string,
    vertices: Float32Array,
    highlight?: boolean,
): UpdateSnapshot {
    return {
        [elementId]: {
            id: elementId,
            __class__: ElementClass.Points,
            vertices,
        },

        [viewId]: generateEntity(ViewClass.Points, {
            id: viewId,
            element: elementId,
            color: highlight ? DEFAULT_HIGHLIGHT_COLOR : DEFAULT_DEFECT_COLOR,
            size: highlight ? 10 : 8,
        }),
    };
}
