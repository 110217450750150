export const AGGREGATE_TITLE = 'Aggregate Geometry';
export const PARAM_MESSAGE =
    'Convert your geometry into a parametric model that is ready for analysis';
export const PARAM_TITLE = 'Parameterize';
export const PARAM_BUTTON_LABEL = 'Parametrize Model';
export const PARAM_OBJECT_TEXT_INPUT_LABEL = 'Name';
export const WORKSPACE_TEXT_INPUT_LABEL = 'Workspace';
export const PARAM_OBJECT_NAME = 'Parameteric from 1 volume shells';
export const APPROXIMATE_PARAM_TIME = 8000;
export const PARAM_SUCCESSFUL_MESSAGE = 'Parametric object is created successfully';
export const PARAM_ERROR_MESSAGE = 'Error creating parametric model';
