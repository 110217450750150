import { SplitLayout } from '@local/split-layout/dist/SplitLayout';
import {
    XyzContext,
    XyzInstanceContextValue,
    createXyzInstanceContext,
} from '@local/webviz/dist/context/createXyzInstanceContext';
import { XyzInstance } from '@local/webviz/dist/types';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Plot } from '../Plot/Plot';
import { ArtifactsPanel } from './ArtifactsPanel/ArtifactsPanel';

function ContentArea(
    xyzInstanceInitialized: boolean,
    setXyzInstanceContextValue: React.Dispatch<
        React.SetStateAction<XyzInstanceContextValue | null>
    >,
) {
    const { workspaceUuid } = useParams();
    return (
        <SplitLayout
            leftPanelComponent={
                <Grid item xs zIndex={1}>
                    {xyzInstanceInitialized && workspaceUuid && <ArtifactsPanel />}
                </Grid>
            }
            rightPanelComponent={
                <Plot
                    initialized={xyzInstanceInitialized}
                    onInitialized={(xyzInstance: XyzInstance) =>
                        setXyzInstanceContextValue(createXyzInstanceContext(xyzInstance))
                    }
                />
            }
        />
    );
}

export function Visualization() {
    const [xyzInstanceContextValue, setXyzInstanceContextValue] =
        useState<XyzInstanceContextValue | null>(null);
    const xyzInstanceInitialized = Boolean(xyzInstanceContextValue);

    return (
        <XyzContext.Provider value={xyzInstanceContextValue}>
            {ContentArea(xyzInstanceInitialized, setXyzInstanceContextValue)}
        </XyzContext.Provider>
    );
}
