import { UpdateSnapshot } from '@local/webviz/src/types/xyz';

import {
    computeBoundingBoxFromCenter,
    getBoundingBoxSnapshot,
} from 'src/visualization/BoundingBoxDialog/snapshot';

import {
    generateLineIndexSequence,
    generateVerticesFromPointIds as getVerticesFromPointIds,
    generateTriangleUsingLineSeg,
    generateBoundaryVertices,
    generateEdgeVertices,
    generateEdgeDisplayData,
    generateSurfaceEdgeDisplayData,
} from './geometry';
import { getLinesSnapshot, getPointsSnapshot } from './snapshots';
import { DefectsHighlight } from './types';
import { toElementViewId, toHighlightViewId } from './uid';

export function generateTriangleBoundary(
    defectName: string,
    { objectId, surface, defectIndex }: DefectsHighlight,
) {
    const { vertices, segments } = generateTriangleUsingLineSeg(surface, defectIndex);
    const triangleBoundaryViewId = toHighlightViewId(objectId, defectName, defectIndex);
    const triangleBoundaryViewSnapshot = getLinesSnapshot(
        toElementViewId(objectId, defectName, defectIndex),
        triangleBoundaryViewId,
        vertices,
        segments,
        true,
    );

    return {
        [triangleBoundaryViewId]: triangleBoundaryViewSnapshot,
    };
}

// TODO: Generalize this function to create snapshots for any type of defect that is represented by
// a collection of edges where we want to highlight the complete collection at once, e.g. holes, fin
// boundaries, naked boundaries, etc.
export function generateHolesBoundary(
    defectName: string,
    { defects, surface, objectId, defectIndex }: DefectsHighlight,
) {
    const holeEdges = defects[defectIndex].edges;
    const holeVertices = generateBoundaryVertices(holeEdges, surface);
    const holeLineSegmentViewId = toHighlightViewId(objectId, defectName, defectIndex);
    const indices = generateLineIndexSequence(holeEdges.length + 1);
    const linesSnapshot = getLinesSnapshot(
        toElementViewId(objectId, defectName, defectIndex),
        holeLineSegmentViewId,
        holeVertices,
        indices,
        true,
    );

    return {
        [holeLineSegmentViewId]: linesSnapshot,
    };
}

export function generatePointsHighlight(
    defectName: string,
    { objectId, surface, defectIndex }: DefectsHighlight,
) {
    const vertices = getVerticesFromPointIds([defectIndex], surface);
    const pointsHighlightViewId = toHighlightViewId(objectId, defectName, defectIndex);
    const pointsHighlightSnapshot = getPointsSnapshot(
        toElementViewId(objectId, defectName, defectIndex),
        pointsHighlightViewId,
        vertices,
        true,
    );

    // Zooming-in on a single point doesn't work properly in xyz. To work around this, we create an
    // invisible bounding box around the point to allow the user to zoom in on the point.
    // TODO: This workaround should be removed once xyz supports proper zooming on points.
    const boundingBox = computeBoundingBoxFromCenter(vertices, 0.1);
    const boundingBoxId = toHighlightViewId(objectId, defectName, defectIndex);
    const boundingBoxSnapshot = getBoundingBoxSnapshot({ label: boundingBoxId, box: boundingBox });

    const invisibleBoundingBoxSnapshot = {
        ...boundingBoxSnapshot,
        [boundingBoxId]: {
            ...boundingBoxSnapshot[boundingBoxId],
            visible: false,
        },
    } as UpdateSnapshot;

    return {
        [pointsHighlightViewId]: pointsHighlightSnapshot,
        [boundingBoxId]: invisibleBoundingBoxSnapshot,
    };
}

/**
 * Generate highlight snapshots for edge defects where each edge represents an individual defect,
 * e.g. for non-manifold edges. This allows us to highlight each edge individually.
 * This function should not be used for defects that are represented by a collection of edges, e.g.
 * holes, where we want to highlight the entire boundary.
 */
export function generateEdgeHighlight(
    defectName: string,
    { defects, surface, objectId, defectIndex }: DefectsHighlight,
) {
    if (defects.length !== 1) throw Error('Bad edge defect data format');

    const edge = defects[0].edges[defectIndex];
    const edgeVertices = generateEdgeVertices(edge, surface);
    const edgeHighlightViewId = toHighlightViewId(objectId, defectName, defectIndex);
    const indices = new Int32Array([0, 1]);
    const linesSnapshot = getLinesSnapshot(
        toElementViewId(objectId, defectName, defectIndex),
        edgeHighlightViewId,
        edgeVertices,
        indices,
        true,
    );

    return {
        [edgeHighlightViewId]: linesSnapshot,
    };
}

/**
 * Generate view snapshot for a highlighted wireframe of a non-partitioning surface with the naked edges highlighted in a different color.
 */
export function generateNonPartitioningSurfaceHighlight(
    defectName: string,
    { defects, surface, objectId, defectIndex }: DefectsHighlight,
) {
    const nakedEdges = defects[defectIndex].edges;
    const patchTriangles = defects[defectIndex].triangles;
    const { vertices, segments } = generateSurfaceEdgeDisplayData(
        surface,
        patchTriangles,
        nakedEdges,
    );
    const surfacePatchLineSegmentViewId = toHighlightViewId(objectId, defectName, defectIndex);
    const patchLinesSnapshot = getLinesSnapshot(
        toElementViewId(objectId, defectName, defectIndex),
        surfacePatchLineSegmentViewId,
        vertices,
        segments,
        true,
        [255, 0, 0],
    );

    const edgeData = generateEdgeDisplayData(nakedEdges, surface);
    const nakedEdgesLineSegmentViewId = toHighlightViewId(
        objectId,
        'naked-edge-object',
        defectIndex,
    );
    const linesSnapshot = getLinesSnapshot(
        toElementViewId(objectId, 'naked-edge-object', defectIndex),
        nakedEdgesLineSegmentViewId,
        edgeData.vertices,
        edgeData.segments,
        true,
        [0, 255, 0],
    );

    return {
        [nakedEdgesLineSegmentViewId]: linesSnapshot,
        [surfacePatchLineSegmentViewId]: patchLinesSnapshot,
    };
}
