// Note: A project assumes the user's current org+workspace combination

export interface GtmProjectInput {
    // Assumes user current workspace and the object_id is a geoscience object id
    object_id: string;
    version_id: string;
}

export interface GtmPoint {
    x: number;
    y: number;
    z: number;
}

export interface GtmBounds {
    minPoint: GtmPoint;
    maxPoint: GtmPoint;
    rotationAngle: number;
    boundaryId: string;
}

export enum GtmEvoFileType {
    GeoscienceObject = 'geoscience_object',
    File = 'file',
}

export interface GtmColor {
    r: number;
    g: number;
    b: number;
    a: number;
}

export interface GtmEvoOutputObject {
    // Assumes user current workspace
    type: GtmEvoFileType;
    // Name of the object -- same as in goose, but without all the prefixes we store
    name: string;
    id: string;
    version_id: string;
    // We store the schema so that we don't always have to query.
    schema: string;
    // Color the user chooses to display the object
    // We store it here because geoscience objects are overly flexible
    // and XYZ is initially supporting color in a way that our application
    // doesn't need.
    // If we want to support the color the user initially gave in their geoscience object
    // we will still likely store it here and have query functions for each different
    // way color can be stored.
    color: GtmColor;
    // Indicates whether the object is in the aggregate geometry.
    // Optional because "aggregation" has no meaning for volumes and the aggregate geometry itself.
    is_aggregated?: boolean;
}

export interface GtmAnalyticalModel {
    // The bounds of the analytical model defines a boundaryId which
    // is used to build the path for the aggregate(composite) model.
    // Since each aggregate geometry is titled "Aggregate Geometry", this
    // allows us to have multiple aggregate geometries in the workspace
    // without conflict.
    bounds: GtmBounds;
    objects: GtmEvoOutputObject[];
    composite_model?: GtmEvoOutputObject;
    volumes?: GtmEvoOutputObject[];
    show_volumes?: boolean;
}

export interface GtmHistoryOperation {
    description: string;
}

export interface GtmHistoryEntry {
    operation: GtmHistoryOperation;
    version_id: string;
}

export interface GtmProject {
    name: string;
    project_description?: string;
    inputs: GtmProjectInput[];
    analytical_models: GtmAnalyticalModel[];
    history: GtmHistoryEntry[];
}
