import { common } from '@local/web-design-system/dist/styles/common';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const styles = (theme: Theme) =>
    ({
        contentsContainer: {
            ...common(theme).thinScrollBar,
            overflowY: 'auto',
            overflowX: 'hidden',
        },
        content: {
            paddingLeft: theme.spacing(),
            paddingRight: theme.spacing(),
            paddingTop: theme.spacing(),
        },
        iconPadding: {
            paddingLeft: theme.spacing(0.5),
            paddingTop: theme.spacing(0.25),
        },
        expansionPanelRoot: {
            boxShadow: 'none',
            width: '100%',
            backgroundColor: 'transparent',
            '&::before': {
                display: 'none',
            },
        },

        expansionPanelExpanded: {
            margin: `${theme.spacing(1)} 0`,
        },
        expansionPanelSummaryRoot: {
            padding: `0 ${theme.spacing()} 0 ${theme.spacing(2)}`,
            minHeight: 0,
        },
        expansionPanelSummaryExpanded: {
            minHeight: `${theme.spacing(2.5)} !important`,
            marginTop: 0,
            marginBottom: 0,
        },
        expansionPanelSummaryExpandIcon: {
            padding: theme.spacing(1),
            fontSize: 16,
            left: -5,
            right: 'auto',
            position: 'absolute' as const,
            top: 0,
            transform: 'translateY(4px) rotate(270deg)',
        },
        expansionPanelSummaryContent: {
            margin: `0 !important`,
            paddingLeft: 4,
            wordBreak: 'break-word' as const,
            flexDirection: 'column-reverse',
        },
        expansionPanelDetailsRoot: {
            padding: 0,
            marginLeft: theme.spacing(5),
        },
    }) as const;

export const useStyles = makeStyles()(styles);
