/* eslint-disable import/no-cycle */
import { createSelector } from '@reduxjs/toolkit';

import { GtmEvoOutputObject, GtmProject, GtmAnalyticalModel } from 'src/gtmProject';

import { RootState } from '../store';
import {
    CurrentProjectState,
    DEFAULT_BASE_ARTIFACTS_STATE,
    DEFAULT_CURRENT_PROJECT_STATE,
    DEFAULT_DEFECT_OBJECT_DATA,
    DefectObjectData,
    DefectsObjectMap,
    ProjectState,
} from './projectSlice.types';

export const initialProjectState: ProjectState = {
    defectsObjectMap: {},
    current: DEFAULT_CURRENT_PROJECT_STATE,
    base: DEFAULT_BASE_ARTIFACTS_STATE,
    currentProjectVersionId: '',
};

type SelectorTypeDefectObjectMap = (state: RootState) => DefectsObjectMap;
type SelectorTypeDefectObjectData = (state: RootState) => DefectObjectData;
type SelectorTypeCurrentProjectState = (state: RootState) => CurrentProjectState;
type SelectorTypeString = (state: RootState) => string;
type SelectorTypeNumber = (state: RootState) => number;
type SelectorTypeGtmProjectData = (state: RootState) => GtmProject;
type SelectorTypeGtmOutputObject = (state: RootState) => GtmEvoOutputObject | undefined;
type SelectorTypeGtmOutputObjects = (state: RootState) => GtmEvoOutputObject[];
type SelectorTypeGtmAnalyticalModel = (state: RootState) => GtmAnalyticalModel | undefined;
type SelectorTypeBoolean = (state: RootState) => boolean;

const projectState = (state: RootState): ProjectState => state.project ?? initialProjectState;

export const selectDefectsMap: SelectorTypeDefectObjectMap = createSelector(
    projectState,
    (projectStateRoot) => projectStateRoot.defectsObjectMap,
);

export const selectDefectsById = (objectId: string): SelectorTypeDefectObjectData =>
    createSelector(
        projectState,
        (projectStateRoot) => projectStateRoot.defectsObjectMap[objectId],
    ) ?? DEFAULT_DEFECT_OBJECT_DATA;

export const selectCurrentProject: SelectorTypeCurrentProjectState = createSelector(
    projectState,
    (projectStateRoot) => projectStateRoot.current,
);

export const selectCurrentProjectName: SelectorTypeString = createSelector(
    projectState,
    (projectStateRoot) => projectStateRoot.current.project?.name ?? '',
);

export const selectCurrentProjectData: SelectorTypeGtmProjectData = createSelector(
    projectState,
    (projectStateRoot) => projectStateRoot.current.project,
);

export const selectCurrentAnalyticalModelIndex: SelectorTypeNumber = createSelector(
    projectState,
    (projectStateRoot) => projectStateRoot.current.selectedAnalyticalModelIndex,
);

export const selectCurrentProjectAnalyticalModel: SelectorTypeGtmAnalyticalModel = createSelector(
    projectState,
    (projectStateRoot) => {
        if (
            !projectStateRoot.current.project ||
            'analytical_models' in projectStateRoot.current.project === false ||
            projectStateRoot.current.project.analytical_models.length === 0
        ) {
            return undefined;
        }
        return projectStateRoot.current.project.analytical_models[
            projectStateRoot.current.selectedAnalyticalModelIndex
        ];
    },
);

export const selectCurrentProjectAnalyticalModelObjects: SelectorTypeGtmOutputObjects =
    createSelector(
        selectCurrentProjectAnalyticalModel,
        (currentAnalyticalModel) => currentAnalyticalModel?.objects ?? [],
    );

export const selectCurrentAggregateGeometry: SelectorTypeGtmOutputObject = createSelector(
    selectCurrentProjectAnalyticalModel,
    (currentAnalyticalModel) => currentAnalyticalModel?.composite_model,
);

export const anAnalyticalModelIsSelected: SelectorTypeBoolean = createSelector(
    selectCurrentAggregateGeometry,
    (currentAggregateGeometry) => currentAggregateGeometry !== undefined,
);

export const selectBaseArtifactsObjects: SelectorTypeGtmOutputObjects = createSelector(
    projectState,
    (projectStateRoot) => projectStateRoot.base.objects ?? [],
);

export const selectCurrentProjectVersionId: SelectorTypeString = createSelector(
    projectState,
    (projectStateRoot) => projectStateRoot.currentProjectVersionId,
);

export const selectCurrentProjectVolumes: SelectorTypeGtmOutputObjects = createSelector(
    projectState,
    (projectStateRoot) =>
        projectStateRoot.current.project?.analytical_models[
            projectStateRoot.current.selectedAnalyticalModelIndex
        ].volumes ?? [],
);

export const selectCurrentShowVolumes: SelectorTypeBoolean = createSelector(
    projectState,
    (projectStateRoot) => {
        if (!projectStateRoot.current.project?.analytical_models) return false;

        return (
            projectStateRoot.current.project?.analytical_models[
                projectStateRoot.current.selectedAnalyticalModelIndex
            ].show_volumes ?? false
        );
    },
);
