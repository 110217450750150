import { NotFoundSvg } from '@local/svgs/dist/svg/NotFoundSvg';
import EmptyState from '@local/web-design-system-2/dist/components/EmptyState/EmptyState';
import Button from '@mui/material/Button';

import {
    BOUNDARIES_UNDEFINED_TEXT,
    BOUNDARIES_UNDEFINED_TEXT_TIP,
    BOUNDARIES_ADD,
} from './BoundariesPanel.constants';
import { useStyles } from './BoundariesPanel.styles';

export function BoundariesPanel({ onClick }: { onClick: () => void }) {
    const { classes } = useStyles();

    return (
        <EmptyState
            title={BOUNDARIES_UNDEFINED_TEXT}
            message={BOUNDARIES_UNDEFINED_TEXT_TIP}
            image={
                <div className={classes.noBoundariesImage}>
                    <NotFoundSvg />
                </div>
            }
            imageSx={{ padding: (theme) => theme.spacing(1, 0) }}
            titleSx={{ padding: (theme) => theme.spacing(1, 0) }}
            bodySx={{ padding: (theme) => theme.spacing(1, 0) }}
            messageSx={{ padding: (theme) => theme.spacing(1.5, 0) }}
        >
            <Button color="secondary" variant="outlined" onClick={onClick}>
                {BOUNDARIES_ADD}
            </Button>
        </EmptyState>
    );
}
