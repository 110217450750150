import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../store';
import { GtmState } from './gtmSlice.types';

export type SelectorTypeString = (state: RootState) => string;

export const initialGtmState: GtmState = {
    workspace: undefined,
};

const gtmState = (state: RootState): GtmState => state.gtm ?? initialGtmState;

export const selectedWorkspaceName: SelectorTypeString = createSelector(
    gtmState,
    (rootGtmState) => rootGtmState.workspace?.name ?? '',
);
