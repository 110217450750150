import { EllipsisMenu } from '@local/web-design-system/dist/components/EllipsisMenu';
import Typography from '@mui/material/Typography';

import { useStyles } from './ObjectActionMenu.styles';
import { ObjectActionMenuProps } from './ObjectActionMenu.types';

export function ObjectActionMenu({
    onRemoveItem,
    onZoomToView,
    zoomToViewActive,
}: ObjectActionMenuProps) {
    const menuOptions = [
        {
            key: 'remove-from-scene',
            ItemComponent: RemoveFromSceneOption,
            action: onRemoveItem,
        },
    ];

    if (zoomToViewActive) {
        menuOptions.push({
            key: 'zoom-to-view',
            ItemComponent: ZoomToViewOption,
            action: onZoomToView,
        });
    }

    return (
        <EllipsisMenu
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            menuOptions={menuOptions}
        />
    );
}

const RemoveFromSceneOption = () => {
    const { classes } = useStyles();
    return <Typography className={classes.menuItem}>Remove from scene</Typography>;
};

const ZoomToViewOption = () => {
    const { classes } = useStyles();
    return <Typography className={classes.menuItem}>Zoom to view</Typography>;
};
