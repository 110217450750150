import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const styles = (theme: Theme) =>
    ({
        root: {
            height: '100%',
            overflow: 'hidden',
            zIndex: 1,
        },
        splitContainer: {
            height: '100%',
        },
        gutter: {
            cursor: 'row-resize',
        },
        lowerPanelContainer: {
            height: '50%',
            paddingBottom: theme.spacing(),
        },
        titleBox: {
            borderBottom: 'none',
        },
        titleText: {
            fontSize: '12px',
            textTransform: 'uppercase',
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
        },
        objectsPanelContainer: {
            height: '50%',
        },
        projectContainer: {
            margin: theme.spacing(1.5),
        },
    }) as const;

export const useStyles = makeStyles()(styles);
