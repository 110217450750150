import packageJson from '../package.json';

export const {
    MODE: ENVIRONMENT,
    NODE_ENV,
    PROD: PRODUCTION_BUILD,
    DEV: DEV_BUILD,
} = import.meta.env;
const { version } = packageJson;

export const ENVIRONMENT_TEST = 'test';
export const ENVIRONMENT_DEVELOPMENT = 'development';
export const ENVIRONMENT_INTEGRATION = 'integration';

// Upload file polling constants
export const RETRIES = 120;
export const WAIT_TIME_MS = 1000;
export const WORKSPACE_PATH = '/workspace';

export const TASK_POLLING_INTERVAL_BASE_MS = 250;
export const TASK_POLLING_INTERVAL_MULT = 0.1;
export const TASK_POLLING_INTERVAL_EXP = 1.0;
export const TASK_POLLING_TIMEOUT_MS = 15 * 60 * 1000;
export const TASK_DEV_BASE_URL = 'https://varify.seq-dev-apis.com';
export const TASK_TOPIC = 'geotechnical-modeller';
export const TASK_ROOT_TASK = 'general-1';

export const PARAMETERIZED_GEOMETRY_SUFFIX = '_parameterized';
export const AGGREGATE_GEOMETRY_NAME = 'Aggregate Geometry';
export const MESH_SCHEMA = '/objects/triangle-mesh/2.0.0/triangle-mesh.schema.json';
export const GTM_FOLDER_PREFIX = '_gtm';

export const TAGLINE = '© Seequent, The Bentley Subsurface Company';
export const VERSION_LABEL = `Version ${version}`;

export const DEFAULT_TOLERANCE = 1e-6; // TODO: a project setting? a better number?
