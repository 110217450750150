import { SurfaceElementState, UpdateSnapshot } from '@local/webviz/dist/types';

import {
    GtmMeshDetectionData,
    GtmMeshDetectorAction,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { DetectionData } from 'src/store/project/projectSlice.types';

export interface DefectData {
    objectId: string;
    defects: DetectionData;
}

export interface DefectsState {
    surface: SurfaceElementState;
    objectId: string;
    defects: GtmMeshDetectionData;
}

export interface DefectsHighlight extends DefectsState {
    defectIndex: number;
}

export type GtmMeshDetectorActionString = keyof typeof GtmMeshDetectorAction;

export interface DefectsSnapshot {
    [viewId: string]: Partial<UpdateSnapshot>;
}

export interface SurfaceGeometry {
    vertices: Float32Array;
    triangles: Int32Array;
}

export interface LineGeometry {
    vertices: Float32Array;
    segments: Int32Array;
}

export const defaultGtmMeshDetectionData: GtmMeshDetectionData = [
    {
        points: [],
        edges: [],
        triangles: [],
    },
];

export interface DefectedArtifact {
    id: string;
    versionId: string;
    name: string;
}
