import { InfoIcon } from '@local/web-design-system/dist/icons/Alerts/InfoIcon';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';

export const ParameterRow = ({
    ParameterElements,
    tooltipText,
}: {
    ParameterElements: any;
    tooltipText: string;
}) => (
    <Grid container item alignItems="center" wrap="nowrap">
        <ParameterElements />
        <Tooltip title={tooltipText} placement="right">
            <Grid>
                <InfoIcon />
            </Grid>
        </Tooltip>
    </Grid>
);
