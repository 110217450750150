/* eslint-disable no-param-reassign */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { initialState } from './selectors';
import {
    VisualizationState,
    SceneObjectData,
    SettingsVisualizationOverlays,
} from './visualizationSlice.types';

export const visualizationSlice = createSlice({
    name: 'visualization',
    initialState,
    reducers: {
        updateOverlays(
            state: VisualizationState,
            { payload }: PayloadAction<Partial<SettingsVisualizationOverlays>>,
        ) {
            state.overlays = { ...state.overlays, ...payload };
        },
        addOrUpdateSceneObject(
            state: VisualizationState,
            { payload: [objectId, objectData] }: PayloadAction<[string, Partial<SceneObjectData>]>,
        ) {
            const initialData = state.sceneObjectMap[objectId];
            state.sceneObjectMap[objectId] = { ...initialData, ...objectData };
        },
        removeSceneObject(state: VisualizationState, { payload }: PayloadAction<string>) {
            delete state.sceneObjectMap[payload];
        },
        clearSceneObjects(state: VisualizationState) {
            state.sceneObjectMap = {};
        },
    },
});

export const { updateOverlays, addOrUpdateSceneObject, removeSceneObject, clearSceneObjects } =
    visualizationSlice.actions;
