import { generateEntity } from '@local/webviz/dist/context/snapshots/base';
import { Color, UpdateSnapshot } from '@local/webviz/dist/types/xyz';
import { ArrayTypes, ElementClass, ViewClass } from '@local/webviz/dist/xyz';

import { GetObjectResponse, NamedDataDownloadUrl } from 'src/apiClients/goose/extendedGooseClient';
import { GtmMeshData } from 'src/apiClients/gtmCompute/gtmComputeApi.types';

export type Vector3 = [number, number, number];
const DEFAULT_GTM_COLOR = [0, 173, 162] as Color;

export function cubeMeshData(): GtmMeshData {
    return {
        // prettier-ignore
        vertices: new Float32Array([
            -1, -1, -1,
            1, -1, -1,
            1, 1, -1,
            -1, 1, -1,
            -1, -1, 1,
            1, -1, 1,
            1, 1, 1,
            -1, 1, 1,
            0, 0, 0,
        ]),
        // prettier-ignore
        triangles: new Int32Array([
            0, 1, 2, 2, 3, 0,
            1, 5, 6, 6, 2, 1,
            5, 4, 7, 7, 6, 5,
            4, 0, 3, 3, 7, 4,
            3, 2, 6, 6, 7, 3,
            1, 0, 4, 4, 5, 1,
        ]),
    };
}

// Note: This is a simple workaround to be able to distinguish between different entities
//       Color generation is subject to change, especially given we will be letting users choose colors.
export const initialColorGenerator = (() => {
    // Palette with similar lightness and saturation but tries to have as distinct hues as possible (from SoilVision)
    const colors: Color[] = [
        [162, 145, 0],
        [240, 96, 74],
        [115, 156, 86],
        [0, 173, 162],
        [197, 127, 91],
        [150, 147, 72],
        [59, 165, 43],
        [0, 167, 240],
        [205, 124, 11],
        [130, 154, 0],
        [0, 168, 67],
        [84, 142, 255],
        [172, 139, 74],
        [102, 160, 0],
        [0, 172, 109],
        [149, 127, 255],
    ];

    let currentIndex = 0;
    const getColor = () => {
        const color = colors[currentIndex % colors.length];
        currentIndex += 1;
        return color;
    };

    return getColor;
})();

const defaultMeshSettings = {
    opacity: 0.5,
    wireframe: false,
    smooth: false,
    color: DEFAULT_GTM_COLOR,
    color_back: DEFAULT_GTM_COLOR,
};

export function getMeshSnapshot(
    elementId: string,
    viewId: string,
    meshData?: GtmMeshData,
): UpdateSnapshot {
    return {
        [elementId]: {
            id: elementId,
            __class__: ElementClass.Surface,
            vertices: meshData ? meshData.vertices : new Float32Array(),
            triangles: meshData ? meshData.triangles : new Int32Array(),
        },
        [viewId]: generateEntity(ViewClass.Surface, {
            id: viewId,
            element: elementId,
            ...defaultMeshSettings,
        }),
    };
}

function extractTriangleMeshObjectDataLink(
    dataHash: string,
    gooseObject: GetObjectResponse,
): string | undefined {
    const dataObject = gooseObject.links.data.find(
        (dataDownloadUrl) =>
            dataDownloadUrl.id === dataHash ||
            (dataDownloadUrl as NamedDataDownloadUrl).name === dataHash,
    );
    return dataObject?.download_url || undefined;
}

export function updateSurfaceMeshUrls(
    elementId: string,
    gooseObject: GetObjectResponse,
): UpdateSnapshot {
    const { indices, vertices } = gooseObject.object.triangles;
    const indicesUrl = extractTriangleMeshObjectDataLink(indices.data, gooseObject);
    const verticesUrl = extractTriangleMeshObjectDataLink(vertices.data, gooseObject);
    return {
        [elementId]: {
            id: elementId,
            __class__: ElementClass.Surface,
            verticesUrl: {
                location: verticesUrl,
                shape: [vertices.length, vertices.width],
                dataType: vertices.data_type as ArrayTypes,
            },
            trianglesUrl: {
                location: indicesUrl,
                shape: [indices.length, indices.width],
                dataType: indices.data_type as ArrayTypes,
            },
        },
    };
}
