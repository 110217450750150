import { makeStyles } from 'tss-react/mui';

const styles = () =>
    ({
        noBoundariesImage: {
            margin: 'auto',
            width: '50%',
        },
    }) as const;

export const useStyles = makeStyles()(styles);
